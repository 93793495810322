import {
	type FuturesMarketAsset,
	type GasPrice,
	OperationalStatus,
	TransactionStatus,
} from '@kwenta/sdk/types'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { notifyError } from 'components/ErrorNotifier'
import { isUserDeniedError } from 'utils/formatters/error'

import { checkSynthetixStatus, fetchKwentaStatus } from './actions'
import type {
	AppState,
	ConditionalOrderModalType,
	FuturesPositionModalType,
	ModalType,
	Transaction,
	TransactionOrder,
} from './types'

export const APP_INITIAL_STATE: AppState = {
	focused: true,
	showModal: undefined,
	showPositionModal: undefined,
	showConditionalOrderModal: undefined,
	gasPrice: {
		baseFeePerGas: '0', // Note that this is used for estimating price and should not be included in the transaction
		maxPriorityFeePerGas: '0',
		maxFeePerGas: '0',
		gasPrice: '0',
	},
	gasSpeed: 'fast',
	synthetixOnMaintenance: false,
	kwentaStatus: {
		status: OperationalStatus.FullyOperational,
		message: '',
		lastUpdatedAt: undefined,
	},
	acknowledgedOrdersWarning: false,
	showBanner: true,
	testnetMode: false,
}

const appSlice = createSlice({
	name: 'app',
	initialState: APP_INITIAL_STATE,
	reducers: {
		setOpenModal: (state, action: PayloadAction<ModalType>) => {
			if (action.payload) {
				state.showPositionModal = null
				state.showConditionalOrderModal = undefined
			}
			state.showModal = {
				type: action.payload,
			}
		},
		setOpenModalWithParams: (
			state,
			action: PayloadAction<{ type: ModalType; params: Record<string, any> }>
		) => {
			if (action.payload) {
				state.showPositionModal = null
				state.showConditionalOrderModal = null
			}
			state.showModal = action.payload
		},
		setShowEditPositionModal: (
			state,
			action: PayloadAction<{
				type: FuturesPositionModalType
				marketAsset: FuturesMarketAsset
				orderId?: number
			} | null>
		) => {
			if (action.payload) {
				state.showModal = undefined
				state.showConditionalOrderModal = null
			}
			state.showPositionModal = action.payload
		},
		setShowConditionalOrderModal: (
			state,
			action: PayloadAction<{
				type: ConditionalOrderModalType
				id: number
				marketAsset: FuturesMarketAsset
			} | null>
		) => {
			if (action.payload) {
				state.showModal = undefined
				state.showPositionModal = null
			}
			state.showConditionalOrderModal = action.payload
		},
		setGasPrice: (state, action: PayloadAction<GasPrice<string>>) => {
			state.gasPrice = action.payload
		},
		setTransaction: (state, action: PayloadAction<Transaction | undefined>) => {
			state.transaction = action.payload ? { ...action.payload, timestamp: Date.now() } : undefined
		},
		updateTransactionStatus: (state, action: PayloadAction<TransactionStatus>) => {
			if (state.transaction) {
				state.transaction.status = action.payload
			}
		},
		updateTransactionHash: (state, action: PayloadAction<string | null>) => {
			if (state.transaction) {
				state.transaction.hash = action.payload
			}
		},
		updateTransactionOrder: (
			state,
			action: PayloadAction<TransactionOrder<string> | undefined>
		) => {
			if (state.transaction) {
				state.transaction.order = action.payload
			}
		},
		updateTransactionStep: (state, action: PayloadAction<string | undefined>) => {
			if (state.transaction) {
				state.transaction.step = action.payload
			}
		},
		handleTransactionError: (state, action: PayloadAction<{ title?: string; message: string }>) => {
			if (isUserDeniedError(action.payload.message)) {
				state.transaction = undefined
			} else {
				notifyError(action.payload.title ?? 'Transaction failed', new Error(action.payload.message))
				if (state.transaction) {
					state.transaction.status = TransactionStatus.Failed
					state.transaction.error = action.payload.message
					state.transaction.order = undefined
				}
			}
		},
		setAcknowledgedOrdersWarning: (state, action: PayloadAction<boolean>) => {
			state.acknowledgedOrdersWarning = action.payload
		},
		setShowBanner: (state, action: PayloadAction<boolean>) => {
			state.showBanner = action.payload
		},
		setFocused: (state, action: PayloadAction<boolean>) => {
			state.focused = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(checkSynthetixStatus.fulfilled, (state, action) => {
			state.synthetixOnMaintenance = action.payload
		})
		builder.addCase(fetchKwentaStatus.fulfilled, (state, action) => {
			state.kwentaStatus = action.payload
		})
	},
})

export const {
	setOpenModal,
	setOpenModalWithParams,
	setShowEditPositionModal,
	setShowConditionalOrderModal,
	setGasPrice,
	setTransaction,
	updateTransactionOrder,
	handleTransactionError,
	updateTransactionStatus,
	updateTransactionStep,
	updateTransactionHash,
	setAcknowledgedOrdersWarning,
	setShowBanner,
	setFocused,
} = appSlice.actions

export default appSlice.reducer
