import {
	type AssetKey,
	FuturesMarketAsset,
	type TokenAllowances,
	type TokenTickers,
	type TokenTickersAndEth,
} from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { selectProviderNetwork } from 'state/futures/common/selectors'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { selectWallet } from 'state/wallet/selectors'

export const selectBalancesAllProviders = createSelector(
	selectWallet,
	(state: RootState) => state.balances,
	(wallet, balances) => {
		if (!wallet) return {}
		return balances.balancesByChain[wallet] ?? {}
	}
)

export const selectBalances = createSelector(
	selectWallet,
	selectProviderNetwork,
	(state: RootState) => state.balances,
	(wallet, chainId, balances) => {
		if (!wallet) return {}
		const tokenBalances = balances.balancesByChain[wallet]?.[chainId] ?? {}
		return tokenBalances
	}
)

export const selectTokenAllowances = createSelector(
	selectWallet,
	selectProviderNetwork,
	(state: RootState) => state.balances,
	(wallet, chainId, balances) => {
		if (!wallet) return {}
		const tokenBalances = balances.balancesByChain[wallet]?.[chainId] ?? {}
		return Object.entries(tokenBalances).reduce<Partial<Record<TokenTickers, TokenAllowances>>>(
			(acc, [asset, balanceAndAllowances]) => {
				const serialized = Object.entries(balanceAndAllowances.allowances).reduce<TokenAllowances>(
					(acc, [spender, value]) => {
						if (value) acc[spender] = wei(value)
						return acc
					},
					{}
				)
				acc[asset as TokenTickers] = serialized
				return acc
			},
			{}
		)
	}
)

export const selectEthWalletBalance = createSelector(
	selectBalances,
	(balances) => balances.ETH?.balance || '0'
)

export const selectV3DepositableBalances = createSelector(selectBalances, (tokenBalances) => {
	return {
		USDC: tokenBalances.USDC?.balance || '0',
		WETH: tokenBalances.WETH?.balance || '0',
		USDx: tokenBalances.USDx?.balance || '0',
		ETH: tokenBalances.ETH?.balance || '0',
		tBTC: tokenBalances.tBTC?.balance || '0',
		sBTC: tokenBalances.sBTC?.balance || '0',
		USDe: tokenBalances.USDe?.balance || '0',
		wSOL: tokenBalances.wSOL?.balance || '0',
	}
})

const convertTokenTickerToAssetKey = (asset: TokenTickersAndEth): AssetKey => {
	const isFuturesMarketAsset = Object.values(FuturesMarketAsset).includes(
		asset as FuturesMarketAsset
	)

	if (isFuturesMarketAsset) {
		return asset as FuturesMarketAsset
	}
	switch (asset) {
		case 'SUSD':
		case 'sUSDe':
		case 'sUSDC':
		case 'USDe':
		case 'USDx':
		case 'USDC':
		case 'DAI':
		case 'USDT':
			return FuturesMarketAsset.USDT
		case 'WETH':
		case 'sETH':
		case 'ETH':
			return FuturesMarketAsset.sETH
		case 'sBTC':
		case 'tBTC':
			return FuturesMarketAsset.sBTC
		case 'swSOL':
		case 'wSOL':
			return FuturesMarketAsset.SOL
		default:
			throw new Error(`Unknown asset: ${asset}`)
	}
}

export const selectV3DepositableBalancesInUSD = createSelector(
	selectV3DepositableBalances,
	selectOffchainPricesInfo,
	(balances, prices) => {
		return Object.entries(balances).reduce(
			(acc, [asset, balance]) => {
				const price = wei(
					prices[convertTokenTickerToAssetKey(asset as TokenTickersAndEth)]?.price || 0
				)
				acc[asset as TokenTickers] = wei(balance).mul(price).toString()
				return acc
			},
			{} as Record<TokenTickers, string>
		)
	}
)
