import {
	type BalancesAndAllowances,
	type NetworkId,
	PerpsProvider,
	type TokenTickers,
} from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { formatEther } from 'ethers/lib/utils'
import type { Address } from 'viem/accounts'

import { selectProviderNetworks } from 'state/futures/common/selectors'
import { setPerpsV3Spenders } from 'state/futures/reducer'
import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { providerIsCrossMargin } from 'utils/futures'

export const fetchBalancesAndAllowances = createAsyncThunk<
	| {
			wallet: Address
			balancesAndAllowances: Partial<Record<NetworkId, BalancesAndAllowances>>
	  }
	| undefined,
	void,
	ThunkConfig
>('balances/fetchBalancesAndAllowances', async (_, { getState, dispatch, extra: { sdk } }) => {
	const wallet = selectWallet(getState())
	const networks = selectProviderNetworks(getState())

	if (!wallet) {
		throw new Error('Wallet not connected')
	}

	const result: any = {}

	for (const provider of Object.values(PerpsProvider)) {
		let spenders: Address[] = []
		let tokens: TokenTickers[] = []
		const network = networks[provider]

		if (providerIsCrossMargin(provider)) {
			const marketProxy = sdk.context.contractConfigs[network]?.snxV3.PerpsV3MarketProxy?.address
			const marginEngine =
				sdk.context.contractConfigs[network]?.snxV3[
					provider === PerpsProvider.SNX_V3_ARB ? 'MarginEngineMultiCollateral' : 'MarginEngine'
				]?.address

			const spotProxy = sdk.context.contractConfigs[network]?.snxV3.SpotV3MarketProxy?.address
			if (marketProxy && spotProxy) {
				spenders = [marketProxy, spotProxy]
				if (marginEngine) spenders.push(marginEngine)
				dispatch(
					setPerpsV3Spenders({
						spenders: {
							marginEngine,
							marketProxy,
							spotProxy,
						},
						provider,
					})
				)
			}
		}

		switch (provider) {
			case PerpsProvider.SNX_V3_BASE:
				tokens = ['USDC', 'sUSDC', 'USDx']
				break
			case PerpsProvider.SNX_V3_ARB:
				tokens = [
					'WETH',
					'USDx',
					'USDC',
					'SUSD',
					'sETH',
					'tBTC',
					'sBTC',
					'wSOL',
					'USDe',
					'sUSDe',
					'swSOL',
				]
				break
			default:
				tokens = ['USDC', 'SUSD']
		}

		const balances = await sdk.tokens.getBalancesAndAllowances(
			tokens,
			wallet,
			spenders as Address[],
			network
		)

		const ethBal = await sdk.tokens.getEthBalance(wallet, network)

		result[network] = {
			...balances,
			ETH: { balance: formatEther(ethBal), allowances: {} },
		}
	}
	return { wallet, balancesAndAllowances: result }
})
