import Wei, { wei } from '@kwenta/wei';
import axios from 'axios';
import { AGGREGATE_ASSET_KEY, APP_MAX_LEVERAGE, DEFAULT_PRICE_IMPACT_DELTA_PERCENT, FUTURES_ENDPOINTS, MAINNET_MARKETS, NEWLY_LISTED_MARKETS, SL_TP_MAX_SIZE, SynthAssetKeysV3, TESTNET_MARKETS, } from '../constants/futures.js';
import { ZERO_WEI } from '../constants/number.js';
import { ETH_UNIT } from '../constants/transactions.js';
import { FuturesMarginType, FuturesMarketAsset, FuturesMarketCategory, FuturesMarketKey, OrderTypeEnum, PerpsProvider, PositionSide, PotentialTradeStatus, } from '../types/futures.js';
import { formatCurrency, formatDollars, weiFromWei } from './number.js';
import { encodeAbiParameters, formatEther, formatUnits, hexToString, maxInt256, parseAbiParameters, stringToHex, } from 'viem';
import { COMMON_ADDRESSES, PYTH_SERVER_BACKUP, PYTH_SERVER_PRIMARY, v3SynthIdToKey, } from '../constants/index';
export const getPerpsV2SubgraphUrl = (networkId) => {
    return FUTURES_ENDPOINTS[networkId] || FUTURES_ENDPOINTS[10];
};
export const marketsForNetwork = (networkId, logError) => {
    switch (networkId) {
        case 10:
            return MAINNET_MARKETS;
        case 11155420:
            return TESTNET_MARKETS;
        default:
            logError?.(new Error('Futures is not supported on this network.'));
            return [];
    }
};
export const getMarketCategory = (asset) => {
    switch (asset) {
        case FuturesMarketKey.sXAGPERP:
        case FuturesMarketKey.sXAUPERP:
            return FuturesMarketCategory.COMMODITY;
        case FuturesMarketKey.sEURPERP:
        case FuturesMarketKey.sGBPPERP:
        case FuturesMarketKey.sAUDPERP:
        case FuturesMarketKey.sJPYPERP:
        case FuturesMarketKey.sJPY1KPERP:
            return FuturesMarketCategory.FOREX;
        default:
            return FuturesMarketCategory.CRYPTO;
    }
};
export const getMarketName = (asset) => {
    if (asset === 'ETHBTC')
        return 'ETH/BTC';
    if (asset === 'SOLETH')
        return 'SOL/ETH';
    if (asset === 'STETHETH')
        return 'stETH/ETH';
    return `${getDisplayAsset(asset)}-PERP`;
};
export const getDisplayAsset = (asset) => {
    if (!asset)
        return null;
    if (asset.toUpperCase() === 'STETH')
        return 'stETH';
    if (asset === 'STETHETH')
        return 'stETH/ETH';
    return asset[0] === 's' ? asset.slice(1) : asset;
};
export const calculateVolumes = (futuresHourlyStats) => {
    const volumes = futuresHourlyStats.reduce((acc, { marketKey, volume, trades }) => {
        const cleanMarketAsset = marketKey !== AGGREGATE_ASSET_KEY
            ? MarketAssetByKey[hexToString(marketKey, { size: 32 })]
            : AGGREGATE_ASSET_KEY;
        acc[cleanMarketAsset] = {
            volume: volume.div(ETH_UNIT).add(acc[cleanMarketAsset]?.volume ?? 0),
            trades: trades.add(acc[cleanMarketAsset]?.trades ?? 0),
        };
        return acc;
    }, {});
    return volumes;
};
export const mapSnxV2Position = (positionDetail, canLiquidatePosition, { asset, address, }) => {
    const { remainingMargin, accessibleMargin, position: { lastPrice, size, margin }, accruedFunding, notionalValue, liquidationPrice, profitLoss, } = positionDetail;
    const initialMargin = weiFromWei(margin.toString());
    const netPnl = weiFromWei(profitLoss.toString());
    const remainingMarginWei = weiFromWei(remainingMargin.toString());
    const uPnl = {
        pnl: weiFromWei(profitLoss.toString()),
        pnlPct: initialMargin.gt(0) ? netPnl.div(weiFromWei(initialMargin)) : wei(0),
    };
    return {
        provider: PerpsProvider.SNX_V2_OP,
        asset,
        remainingMargin: remainingMarginWei,
        accessibleMargin: weiFromWei(accessibleMargin.toString()),
        marketAddress: address,
        canLiquidatePosition: !!canLiquidatePosition,
        side: weiFromWei(size.toString()).gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT,
        notionalValue: weiFromWei(notionalValue.toString()).abs(),
        accruedFunding: weiFromWei(accruedFunding.toString()),
        initialMargin,
        lastPrice: weiFromWei(lastPrice.toString()),
        size: weiFromWei(size.toString()).abs(),
        liquidationPrice: weiFromWei(liquidationPrice.toString()),
        initialLeverage: initialMargin.gt(0)
            ? weiFromWei(size.toString()).mul(weiFromWei(lastPrice.toString())).div(initialMargin).abs()
            : wei(0),
        uPnl,
        marginRatio: weiFromWei(notionalValue.toString()).eq(ZERO_WEI)
            ? ZERO_WEI
            : remainingMarginWei.div(weiFromWei(notionalValue.toString()).abs()),
        leverage: remainingMarginWei.eq(ZERO_WEI)
            ? ZERO_WEI
            : weiFromWei(notionalValue.toString()).div(remainingMarginWei.toString()).abs(),
    };
};
export const mapPerpsV2Positions = (provider, futuresPositions) => {
    return futuresPositions.map(({ id, openTimestamp, closeTimestamp, timestamp, market, asset, account, abstractAccount, isOpen, isLiquidated, trades, totalVolume, initialMargin, netTransfers, margin, pnl, feesPaid, netFunding, pnlWithFeesPaid, totalDeposits, entryPrice, avgEntryPrice, exitPrice, allTrades, size, }) => {
        const entryPriceWei = wei(entryPrice).div(ETH_UNIT);
        const feesWei = wei(feesPaid || 0).div(ETH_UNIT);
        const sizeWei = weiFromWei(size);
        const firstTradeSize = wei(allTrades[0]?.size ?? 0);
        const marginWei = wei(margin).div(ETH_UNIT);
        const initialMarginWei = wei(initialMargin).div(ETH_UNIT);
        const netPnlWei = wei(pnlWithFeesPaid).div(ETH_UNIT);
        const posSizeForSide = !sizeWei.eq(0)
            ? sizeWei
            : wei(allTrades[allTrades.length - 1]?.size ?? 0).neg(); // Reverse of last trade
        const side = posSizeForSide.gte(wei(0)) ? PositionSide.LONG : PositionSide.SHORT;
        const rPnl = {
            pnl: wei(pnl).div(ETH_UNIT),
            netPnl: netPnlWei,
            netPnlPct: netPnlWei.div(initialMarginWei),
        };
        const position = {
            provider,
            account: account,
            abstractAccount,
            marketAddress: market,
            asset: hexToString(asset, { size: 32 }),
            details: {
                id,
                side,
                size: sizeWei.abs(),
                status: isOpen ? 'open' : isLiquidated ? 'liquidated' : 'closed',
                accountType: FuturesMarginType.ISOLATED_MARGIN,
                timestamp: wei(timestamp).mul(1000).toNumber(),
                openTimestamp: wei(openTimestamp).mul(1000).toNumber(),
                closeTimestamp: wei(closeTimestamp ?? 0)
                    .mul(1000)
                    .toNumber(),
                stats: {
                    totalVolume: wei(totalVolume).div(ETH_UNIT),
                    trades: Number(trades) + (isLiquidated ? 1 : 0),
                    totalDeposits: wei(totalDeposits || 0).div(ETH_UNIT),
                    netTransfers: wei(netTransfers || 0).div(ETH_UNIT),
                },
                margin: {
                    remainingMargin: ZERO_WEI,
                    accessibleMargin: ZERO_WEI,
                    notionalValue: ZERO_WEI,
                    marginRatio: ZERO_WEI, // TODO: same as notionalValue?
                    initialMargin: initialMarginWei,
                    initialLeverage: ZERO_WEI,
                    leverage: marginWei.eq(wei(0))
                        ? wei(0)
                        : firstTradeSize.mul(entryPriceWei).div(marginWei).abs(),
                },
                price: {
                    entryPrice: entryPriceWei,
                    exitPrice: wei(exitPrice || 0).div(ETH_UNIT),
                    avgEntryPrice: wei(avgEntryPrice).div(ETH_UNIT),
                },
                pnl: {
                    rPnl,
                    totalPnl: rPnl,
                },
                fees: {
                    netFunding: wei(netFunding || 0).div(ETH_UNIT),
                    feesPaid: feesWei,
                    owedInterest: ZERO_WEI,
                    accruedFunding: ZERO_WEI,
                },
                liquidation: {
                    isLiquidated,
                },
            },
        };
        return position;
    });
};
export const formatV2DelayedOrder = (account, marketAddress, order) => {
    const { isOffchain, sizeDelta, desiredFillPrice, targetRoundId, commitDeposit, keeperDeposit, executableAtTime, intentionTime, } = order;
    return {
        account: account,
        marketAddress: marketAddress,
        size: wei(sizeDelta).toString(),
        commitDeposit: wei(commitDeposit).toString(),
        keeperDeposit: wei(keeperDeposit).toString(),
        submittedAtTimestamp: Number(intentionTime) * 1000,
        executableAtTimestamp: Number(executableAtTime) * 1000,
        isOffchain: isOffchain,
        desiredFillPrice: wei(desiredFillPrice).toString(),
        targetRoundId: wei(targetRoundId).toString(),
        orderType: isOffchain ? 'Delayed Market' : 'Delayed',
        side: wei(sizeDelta).gt(0) ? PositionSide.LONG : PositionSide.SHORT,
    };
};
export const formatPotentialTrade = (preview, skewAdjustedPrice, nativeSizeDelta, leverageSide, provider) => {
    const { fee, liqPrice, margin, price, size, status } = preview;
    const marginWei = wei(margin);
    const sizeWei = wei(size);
    const priceWei = wei(price);
    const notionalValue = sizeWei.mul(priceWei);
    const leverage = marginWei.gt(0) ? notionalValue.div(marginWei) : ZERO_WEI;
    const priceImpact = skewAdjustedPrice.gt(0)
        ? priceWei.sub(skewAdjustedPrice).div(skewAdjustedPrice).abs()
        : ZERO_WEI;
    return {
        fee: wei(fee),
        liqPrice: wei(liqPrice),
        margin: marginWei,
        fillPrice: priceWei,
        newSize: sizeWei,
        sizeDelta: nativeSizeDelta,
        side: leverageSide,
        leverage: leverage,
        notionalValue: notionalValue,
        status,
        showStatus: status > 0, // 0 is success
        statusMessage: getTradeStatusMessage(status, provider),
        priceImpact: priceImpact,
        exceedsPriceProtection: priceImpact.mul(100).gt(getDefaultPriceImpact(OrderTypeEnum.MARKET)),
    };
};
const SUCCESS = 'Success';
const UNKNOWN = 'Unknown';
export const getTradeStatusMessage = (status, provider) => {
    if (typeof status !== 'number') {
        return UNKNOWN;
    }
    if (status === 0) {
        return SUCCESS;
    }
    else if (PotentialTradeStatus[status]) {
        return potentialTradeStatusToMessage(status, provider);
    }
    else {
        return UNKNOWN;
    }
};
// https://github.com/Synthetixio/synthetix/blob/4d2add4f74c68ac4f1106f6e7be4c31d4f1ccc76/contracts/PerpsV2MarketBase.sol#L130-L141
const POTENTIAL_TRADE_STATUS_TO_MESSAGE = {
    OK: 'Ok',
    INVALID_PRICE: 'Invalid price',
    INVALID_ORDER_PRICE: 'Invalid order price',
    PRICE_OUT_OF_BOUNDS: 'Price out of acceptable range',
    CAN_LIQUIDATE: 'Position can be liquidated',
    CANNOT_LIQUIDATE: 'Position cannot be liquidated',
    MAX_MARKET_SIZE_EXCEEDED: 'Open interest limit exceeded',
    MAX_LEVERAGE_EXCEEDED: 'Max leverage exceeded (larger positions have lower max leverage)',
    INSUFFICIENT_MARGIN: 'Insufficient margin',
    NOT_PERMITTED: 'Not permitted by this address',
    NO_POSITION_OPEN: 'No position open',
    PRICE_TOO_VOLATILE: 'Price too volatile',
    PRICE_IMPACT_TOLERANCE_EXCEEDED: 'Price impact tolerance exceeded',
    INSUFFICIENT_FREE_MARGIN: `You don't have enough USDC for this trade`,
};
const POTENTIAL_TRADE_STATUS_TO_MESSAGE_OP = {
    ...POTENTIAL_TRADE_STATUS_TO_MESSAGE,
    INSUFFICIENT_FREE_MARGIN: `You don't have enough sUSD for this trade`,
};
export const potentialTradeStatusToMessage = (key, provider) => {
    if (provider === PerpsProvider.SNX_V2_OP) {
        return POTENTIAL_TRADE_STATUS_TO_MESSAGE_OP[PotentialTradeStatus[key]];
    }
    else {
        return POTENTIAL_TRADE_STATUS_TO_MESSAGE[PotentialTradeStatus[key]];
    }
};
export const getPythNetworkUrl = (server = 'PRIMARY') => {
    const defaultPythServerMainnet = server === 'BACKUP' ? PYTH_SERVER_BACKUP : PYTH_SERVER_PRIMARY;
    return defaultPythServerMainnet;
};
export const normalizePythId = (id) => (id.startsWith('0x') ? id : `0x${id}`);
export const mapConditionalOrderFromContract = (orderDetails, account) => {
    const marketKey = hexToString(orderDetails.marketKey, { size: 32 });
    const asset = MarketAssetByKey[marketKey];
    const sizeDelta = wei(orderDetails.sizeDelta);
    const size = sizeDelta.abs();
    const baseType = orderDetails.conditionalOrderType === 0 ? OrderTypeEnum.LIMIT : OrderTypeEnum.STOP;
    const orderType = formatSnxV2OrderType(baseType, orderDetails.reduceOnly);
    return {
        id: orderDetails.id,
        account: account,
        size: sizeDelta.toString(),
        marginDelta: wei(orderDetails.marginDelta).toString(),
        orderType: orderType,
        orderTypeDisplay: formatOrderDisplayType(orderType),
        // TODO: Rename when ABI is updated
        desiredFillPrice: wei(orderDetails.desiredFillPrice).toString(),
        targetPrice: wei(orderDetails.targetPrice).toString(),
        reduceOnly: orderDetails.reduceOnly,
        sizeTxt: size.abs().eq(SL_TP_MAX_SIZE)
            ? 'Close'
            : formatCurrency(asset, size, {
                currencyKey: getDisplayAsset(asset) ?? '',
                minDecimals: size.lt(0.01) ? 4 : 2,
            }),
        targetPriceTxt: formatDollars(wei(orderDetails.targetPrice)),
        marketKey: marketKey,
        market: getMarketName(asset),
        asset: asset,
        side: sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
        isStale: false,
        isExecutable: false,
        isSlTp: size.eq(SL_TP_MAX_SIZE),
    };
};
const mapOrderType = (orderType) => {
    return orderType === 'NextPrice'
        ? 'Next Price'
        : orderType === 'StopMarket'
            ? 'Stop'
            : orderType === 'DelayedOffchain'
                ? 'Market'
                : orderType;
};
export const mapTrades = (futuresTrades) => {
    return futuresTrades.map(({ id, timestamp, account, margin, size, price, asset, positionId, positionClosed, pnl, feesPaid, orderType, fundingAccrued, abstractAccount, vipTier, feeRebate, blockNumber, }) => {
        const fees = weiFromWei(feesPaid);
        const assetString = hexToString(asset, { size: 32 });
        return {
            id,
            marketId: MarketKeyByAsset[assetString],
            asset: assetString,
            account,
            ownerAddress: abstractAccount,
            accountType: FuturesMarginType.ISOLATED_MARGIN,
            margin: weiFromWei(margin),
            sizeDelta: weiFromWei(size),
            fillPrice: weiFromWei(price),
            txnHash: id.split('-')[0].toString(),
            timestamp: timestamp.toNumber(),
            positionId,
            positionClosed,
            side: size.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
            pnl: weiFromWei(pnl),
            pnlWithFeesPaid: weiFromWei(pnl).sub(fees).add(weiFromWei(fundingAccrued)),
            totalFees: fees,
            orderType: mapOrderType(orderType),
            fundingAccrued: weiFromWei(fundingAccrued),
            vipTier: vipTier ?? 0,
            feeRebate: weiFromWei(feeRebate),
            blockNumber: Number(blockNumber),
        };
    });
};
export const mapMarginTransfers = (marginTransfers) => {
    return marginTransfers.map(({ timestamp, account, market, size, asset, txHash }) => {
        const sizeWei = new Wei(size);
        const numTimestamp = wei(timestamp).toNumber();
        return {
            timestamp: numTimestamp,
            account,
            market,
            size: sizeWei.div(ETH_UNIT).toNumber(),
            action: sizeWei.gt(0) ? 'deposit' : 'withdraw',
            asset: hexToString(asset, { size: 32 }),
            txHash,
        };
    });
};
export const mapSmartMarginTransfers = (marginTransfers) => {
    return marginTransfers.map(({ timestamp, account, size, txHash }) => {
        const sizeWei = new Wei(size);
        const numTimestamp = wei(timestamp).toNumber();
        return {
            timestamp: numTimestamp,
            account,
            size: sizeWei.div(ETH_UNIT).toNumber(),
            action: sizeWei.gt(0) ? 'deposit' : 'withdraw',
            txHash,
        };
    });
};
const mapOrderTypeToBigInt = (orderType) => {
    switch (orderType) {
        case OrderTypeEnum.LIMIT:
        case OrderTypeEnum.TAKE_PROFIT:
            return BigInt(0);
        case OrderTypeEnum.STOP:
        case OrderTypeEnum.STOP_LOSS:
            return BigInt(1);
        default:
            return null;
    }
};
export const encodeConditionalOrderParams = (marketKey, tradeInputs, type, reduceOnly) => {
    const typeValue = mapOrderTypeToBigInt(type);
    if (typeValue === null) {
        throw new Error('Market orders are not supported');
    }
    return encodeAbiParameters(parseAbiParameters('bytes32, int256, int256, uint256, uint256, uint256, bool'), [
        stringToHex(marketKey, { size: 32 }),
        tradeInputs.marginDelta.toBigInt(),
        tradeInputs.sizeDelta.toBigInt(),
        tradeInputs.price.toBigInt(),
        typeValue,
        tradeInputs.desiredFillPrice.toBigInt(),
        reduceOnly,
    ]);
};
export const calculateTotalFees = (totalFees) => {
    const fees = totalFees
        .map(({ feesKwenta }) => formatEther(feesKwenta.toBigInt()))
        .reduce((acc, curr) => acc.add(wei(curr)), ZERO_WEI);
    return fees ?? ZERO_WEI;
};
export const calculateFeesForAccount = (feesForAccount) => {
    const fees = feesForAccount
        .map((trade) => formatEther(trade.feesPaid.sub(trade.keeperFeesPaid).toBigInt()))
        .reduce((acc, curr) => acc.add(wei(curr)), ZERO_WEI);
    return fees ?? ZERO_WEI;
};
export const encodeSubmitOffchainOrderParams = (marketAddress, sizeDelta, desiredFillPrice) => {
    return encodeAbiParameters(parseAbiParameters('address, int256, uint256'), [
        marketAddress,
        sizeDelta.toBigInt(),
        desiredFillPrice.toBigInt(),
    ]);
};
export const encodeCloseOffchainOrderParams = (marketAddress, desiredFillPrice) => {
    return encodeAbiParameters(parseAbiParameters('address, uint256'), [
        marketAddress,
        desiredFillPrice.toBigInt(),
    ]);
};
export const encodeModifyMarketMarginParams = (marketAddress, marginDelta) => {
    return encodeAbiParameters(parseAbiParameters('address, int256'), [
        marketAddress,
        marginDelta.toBigInt(),
    ]);
};
export const formatSnxV2OrderType = (orderType, reduceOnly) => {
    if (reduceOnly) {
        return orderType === OrderTypeEnum.LIMIT ? OrderTypeEnum.TAKE_PROFIT : OrderTypeEnum.STOP_LOSS;
    }
    return orderType;
};
export const formatOrderDisplayType = (orderType) => {
    return orderType;
};
export const calculateDesiredFillPrice = (sizeDelta, marketPrice, priceImpactPercent) => {
    const priceImpactDecimalPct = priceImpactPercent.div(100);
    return sizeDelta.lt(0)
        ? marketPrice.mul(wei(1).sub(priceImpactDecimalPct))
        : marketPrice.mul(priceImpactDecimalPct.add(1));
};
export const getDefaultPriceImpactDeltaPercent = (orderType) => {
    switch (orderType) {
        case OrderTypeEnum.MARKET:
            return DEFAULT_PRICE_IMPACT_DELTA_PERCENT.MARKET;
        case OrderTypeEnum.LIMIT:
            return DEFAULT_PRICE_IMPACT_DELTA_PERCENT.LIMIT;
        case OrderTypeEnum.STOP:
            return DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP;
        case OrderTypeEnum.TAKE_PROFIT:
            return DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT;
        case OrderTypeEnum.STOP_LOSS:
            return DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS;
        default:
            return DEFAULT_PRICE_IMPACT_DELTA_PERCENT.MARKET;
    }
};
export const getDefaultPriceImpact = (orderType) => {
    return wei(getDefaultPriceImpactDeltaPercent(orderType));
};
// Returns the max leverage without buffer
export const appAdjustedLeverage = (marketLeverage) => {
    if (marketLeverage.gte(APP_MAX_LEVERAGE))
        return APP_MAX_LEVERAGE;
    return wei(25);
};
export const MarketAssetByKey = {
    [FuturesMarketKey.sBTCPERP]: FuturesMarketAsset.sBTC,
    [FuturesMarketKey.sETHPERP]: FuturesMarketAsset.sETH,
    [FuturesMarketKey.sWIFPERP]: FuturesMarketAsset.WIF,
    [FuturesMarketKey.sLINKPERP]: FuturesMarketAsset.LINK,
    [FuturesMarketKey.sSOLPERP]: FuturesMarketAsset.SOL,
    [FuturesMarketKey.sAVAXPERP]: FuturesMarketAsset.AVAX,
    [FuturesMarketKey.sAAVEPERP]: FuturesMarketAsset.AAVE,
    [FuturesMarketKey.sUNIPERP]: FuturesMarketAsset.UNI,
    [FuturesMarketKey.sPOLPERP]: FuturesMarketAsset.POL,
    [FuturesMarketKey.sXAUPERP]: FuturesMarketAsset.XAU,
    [FuturesMarketKey.sXAGPERP]: FuturesMarketAsset.XAG,
    [FuturesMarketKey.sEURPERP]: FuturesMarketAsset.EUR,
    [FuturesMarketKey.sAPEPERP]: FuturesMarketAsset.APE,
    [FuturesMarketKey.sDYDXPERP]: FuturesMarketAsset.DYDX,
    [FuturesMarketKey.sBNBPERP]: FuturesMarketAsset.BNB,
    [FuturesMarketKey.sDOGEPERP]: FuturesMarketAsset.DOGE,
    [FuturesMarketKey.sOPPERP]: FuturesMarketAsset.OP,
    [FuturesMarketKey.sARBPERP]: FuturesMarketAsset.ARB,
    [FuturesMarketKey.sATOMPERP]: FuturesMarketAsset.ATOM,
    [FuturesMarketKey.sFTMPERP]: FuturesMarketAsset.FTM,
    [FuturesMarketKey.sNEARPERP]: FuturesMarketAsset.NEAR,
    [FuturesMarketKey.sFLOWPERP]: FuturesMarketAsset.FLOW,
    [FuturesMarketKey.sAXSPERP]: FuturesMarketAsset.AXS,
    [FuturesMarketKey.sAUDPERP]: FuturesMarketAsset.AUD,
    [FuturesMarketKey.sGBPPERP]: FuturesMarketAsset.GBP,
    [FuturesMarketKey.sAPTPERP]: FuturesMarketAsset.APT,
    [FuturesMarketKey.sLDOPERP]: FuturesMarketAsset.LDO,
    [FuturesMarketKey.sADAPERP]: FuturesMarketAsset.ADA,
    [FuturesMarketKey.sGMXPERP]: FuturesMarketAsset.GMX,
    [FuturesMarketKey.sFILPERP]: FuturesMarketAsset.FIL,
    [FuturesMarketKey.sLTCPERP]: FuturesMarketAsset.LTC,
    [FuturesMarketKey.sBCHPERP]: FuturesMarketAsset.BCH,
    [FuturesMarketKey.sSHIBPERP]: FuturesMarketAsset.SHIB,
    [FuturesMarketKey.sCRVPERP]: FuturesMarketAsset.CRV,
    [FuturesMarketKey.sSUIPERP]: FuturesMarketAsset.SUI,
    [FuturesMarketKey.sPEPEPERP]: FuturesMarketAsset.PEPE,
    [FuturesMarketKey.sBLURPERP]: FuturesMarketAsset.BLUR,
    [FuturesMarketKey.sXRPPERP]: FuturesMarketAsset.XRP,
    [FuturesMarketKey.sDOTPERP]: FuturesMarketAsset.DOT,
    [FuturesMarketKey.sFLOKIPERP]: FuturesMarketAsset.FLOKI,
    [FuturesMarketKey.sINJPERP]: FuturesMarketAsset.INJ,
    [FuturesMarketKey.sTRXPERP]: FuturesMarketAsset.TRX,
    [FuturesMarketKey.sSTETHPERP]: FuturesMarketAsset.STETH,
    [FuturesMarketKey.sETHBTCPERP]: FuturesMarketAsset.ETHBTC,
    [FuturesMarketKey.sXMRPERP]: FuturesMarketAsset.XMR,
    [FuturesMarketKey.sMAVPERP]: FuturesMarketAsset.MAV,
    [FuturesMarketKey.sETCPERP]: FuturesMarketAsset.ETC,
    [FuturesMarketKey.sCOMPPERP]: FuturesMarketAsset.COMP,
    [FuturesMarketKey.sYFIPERP]: FuturesMarketAsset.YFI,
    [FuturesMarketKey.sMKRPERP]: FuturesMarketAsset.MKR,
    [FuturesMarketKey.sRPLPERP]: FuturesMarketAsset.RPL,
    [FuturesMarketKey.sWLDPERP]: FuturesMarketAsset.WLD,
    [FuturesMarketKey.sUSDTPERP]: FuturesMarketAsset.USDT,
    [FuturesMarketKey.sSEIPERP]: FuturesMarketAsset.SEI,
    [FuturesMarketKey.sRUNEPERP]: FuturesMarketAsset.RUNE,
    [FuturesMarketKey.sSUSHIPERP]: FuturesMarketAsset.SUSHI,
    [FuturesMarketKey.sZECPERP]: FuturesMarketAsset.ZEC,
    [FuturesMarketKey.sXTZPERP]: FuturesMarketAsset.XTZ,
    [FuturesMarketKey.sUMAPERP]: FuturesMarketAsset.UMA,
    [FuturesMarketKey.sENJPERP]: FuturesMarketAsset.ENJ,
    [FuturesMarketKey.sICPPERP]: FuturesMarketAsset.ICP,
    [FuturesMarketKey.sXLMPERP]: FuturesMarketAsset.XLM,
    [FuturesMarketKey.s1INCHPERP]: FuturesMarketAsset.ONEINCH,
    [FuturesMarketKey.sEOSPERP]: FuturesMarketAsset.EOS,
    [FuturesMarketKey.sCELOPERP]: FuturesMarketAsset.CELO,
    [FuturesMarketKey.sALGOPERP]: FuturesMarketAsset.ALGO,
    [FuturesMarketKey.sZRXPERP]: FuturesMarketAsset.ZRX,
    [FuturesMarketKey.sBALPERP]: FuturesMarketAsset.BAL,
    [FuturesMarketKey.sFXSPERP]: FuturesMarketAsset.FXS,
    [FuturesMarketKey.sKNCPERP]: FuturesMarketAsset.KNC,
    [FuturesMarketKey.sRNDRPERP]: FuturesMarketAsset.RNDR,
    [FuturesMarketKey.sONEPERP]: FuturesMarketAsset.ONE,
    [FuturesMarketKey.sPERPPERP]: FuturesMarketAsset.PERP,
    [FuturesMarketKey.sZILPERP]: FuturesMarketAsset.ZIL,
    [FuturesMarketKey.sSTETHETHPERP]: FuturesMarketAsset.STETHETH,
    [FuturesMarketKey.sSNXPERP]: FuturesMarketAsset.SNX,
    [FuturesMarketKey.sTIAPERP]: FuturesMarketAsset.TIA,
    [FuturesMarketKey.sIMXPERP]: FuturesMarketAsset.IMX,
    [FuturesMarketKey.sTRBPERP]: FuturesMarketAsset.TRB,
    [FuturesMarketKey.sMEMEPERP]: FuturesMarketAsset.MEME,
    [FuturesMarketKey.sANKRPERP]: FuturesMarketAsset.ANKR,
    [FuturesMarketKey.sFETPERP]: FuturesMarketAsset.FET,
    [FuturesMarketKey.sGRTPERP]: FuturesMarketAsset.GRT,
    [FuturesMarketKey.sPYTHPERP]: FuturesMarketAsset.PYTH,
    [FuturesMarketKey.sBONKPERP]: FuturesMarketAsset.BONK,
    [FuturesMarketKey.sORDIPERP]: FuturesMarketAsset.ORDI,
    [FuturesMarketKey.sJTOPERP]: FuturesMarketAsset.JTO,
    [FuturesMarketKey.sCVXPERP]: FuturesMarketAsset.CVX,
    [FuturesMarketKey.sJUPPERP]: FuturesMarketAsset.JUP,
    [FuturesMarketKey.sPENDLEPERP]: FuturesMarketAsset.PENDLE,
    [FuturesMarketKey.sSTRKPERP]: FuturesMarketAsset.STRK,
    [FuturesMarketKey.sWPERP]: FuturesMarketAsset.W,
    [FuturesMarketKey.sENAPERP]: FuturesMarketAsset.ENA,
    [FuturesMarketKey.sTONPERP]: FuturesMarketAsset.TON,
    [FuturesMarketKey.sARKMPERP]: FuturesMarketAsset.ARKM,
    [FuturesMarketKey.sGALAPERP]: FuturesMarketAsset.GALA,
    [FuturesMarketKey.sTAOPERP]: FuturesMarketAsset.TAO,
    [FuturesMarketKey.sBOMEPERP]: FuturesMarketAsset.BOME,
    [FuturesMarketKey.sETHFIPERP]: FuturesMarketAsset.ETHFI,
    [FuturesMarketKey.sSTXPERP]: FuturesMarketAsset.STX,
    [FuturesMarketKey.sAXLPERP]: FuturesMarketAsset.AXL,
    [FuturesMarketKey['sBTC²PERP']]: FuturesMarketAsset['BTC²'],
    [FuturesMarketKey['sETH²PERP']]: FuturesMarketAsset['ETH²'],
    [FuturesMarketKey.sRLBPERP]: FuturesMarketAsset.RLB,
    [FuturesMarketKey.sJPYPERP]: FuturesMarketAsset.JPY,
    [FuturesMarketKey.sMOGPERP]: FuturesMarketAsset.MOG,
    [FuturesMarketKey.sDOGE10PERP]: FuturesMarketAsset.DOGE10,
    [FuturesMarketKey.sMOG1MPERP]: FuturesMarketAsset.MOG1M,
    [FuturesMarketKey.sJPY1KPERP]: FuturesMarketAsset.JPY1K,
    [FuturesMarketKey.sAEROPERP]: FuturesMarketAsset.AERO,
    [FuturesMarketKey.sPOPCATPERP]: FuturesMarketAsset.POPCAT,
    [FuturesMarketKey.sMEEMPERP]: FuturesMarketAsset.MEEM,
    [FuturesMarketKey.sEIGENPERP]: FuturesMarketAsset.EIGEN,
    [FuturesMarketKey.sIOPERP]: FuturesMarketAsset.IO,
    [FuturesMarketKey.sMEWPERP]: FuturesMarketAsset.MEW,
    [FuturesMarketKey.sNOTPERP]: FuturesMarketAsset.NOT,
    [FuturesMarketKey.sPEOPLEPERP]: FuturesMarketAsset.PEOPLE,
    [FuturesMarketKey.sRENDERPERP]: FuturesMarketAsset.RENDER,
    [FuturesMarketKey.sSATSPERP]: FuturesMarketAsset.SATS,
    [FuturesMarketKey.sZROPERP]: FuturesMarketAsset.ZRO,
    [FuturesMarketKey.sGOATPERP]: FuturesMarketAsset.GOAT,
    [FuturesMarketKey.sSAFEPERP]: FuturesMarketAsset.SAFE,
    [FuturesMarketKey.sMOODENGPERP]: FuturesMarketAsset.MOODENG,
    [FuturesMarketKey.sCATPERP]: FuturesMarketAsset.CAT,
    [FuturesMarketKey.sPNUTPERP]: FuturesMarketAsset.PNUT,
    [FuturesMarketKey.sDEGENPERP]: FuturesMarketAsset.DEGEN,
    [FuturesMarketKey.sSLERFPERP]: FuturesMarketAsset.SLERF,
    [FuturesMarketKey.sMORPHOPERP]: FuturesMarketAsset.MORPHO,
    [FuturesMarketKey.sCHILLGUYPERP]: FuturesMarketAsset.CHILLGUY,
    [FuturesMarketKey.sSOLETHPERP]: FuturesMarketAsset.SOLETH,
    [FuturesMarketKey.sVIRTUALPERP]: FuturesMarketAsset.VIRTUAL,
};
export const MarketKeyByAsset = {
    [FuturesMarketAsset.sBTC]: FuturesMarketKey.sBTCPERP,
    [FuturesMarketAsset.sETH]: FuturesMarketKey.sETHPERP,
    [FuturesMarketAsset.WIF]: FuturesMarketKey.sWIFPERP,
    [FuturesMarketAsset.LINK]: FuturesMarketKey.sLINKPERP,
    [FuturesMarketAsset.SOL]: FuturesMarketKey.sSOLPERP,
    [FuturesMarketAsset.AVAX]: FuturesMarketKey.sAVAXPERP,
    [FuturesMarketAsset.AAVE]: FuturesMarketKey.sAAVEPERP,
    [FuturesMarketAsset.UNI]: FuturesMarketKey.sUNIPERP,
    [FuturesMarketAsset.POL]: FuturesMarketKey.sPOLPERP,
    [FuturesMarketAsset.XAU]: FuturesMarketKey.sXAUPERP,
    [FuturesMarketAsset.XAG]: FuturesMarketKey.sXAGPERP,
    [FuturesMarketAsset.EUR]: FuturesMarketKey.sEURPERP,
    [FuturesMarketAsset.APE]: FuturesMarketKey.sAPEPERP,
    [FuturesMarketAsset.DYDX]: FuturesMarketKey.sDYDXPERP,
    [FuturesMarketAsset.BNB]: FuturesMarketKey.sBNBPERP,
    [FuturesMarketAsset.DOGE]: FuturesMarketKey.sDOGEPERP,
    [FuturesMarketAsset.OP]: FuturesMarketKey.sOPPERP,
    [FuturesMarketAsset.ARB]: FuturesMarketKey.sARBPERP,
    [FuturesMarketAsset.ATOM]: FuturesMarketKey.sATOMPERP,
    [FuturesMarketAsset.FTM]: FuturesMarketKey.sFTMPERP,
    [FuturesMarketAsset.NEAR]: FuturesMarketKey.sNEARPERP,
    [FuturesMarketAsset.FLOW]: FuturesMarketKey.sFLOWPERP,
    [FuturesMarketAsset.AXS]: FuturesMarketKey.sAXSPERP,
    [FuturesMarketAsset.AUD]: FuturesMarketKey.sAUDPERP,
    [FuturesMarketAsset.GBP]: FuturesMarketKey.sGBPPERP,
    [FuturesMarketAsset.APT]: FuturesMarketKey.sAPTPERP,
    [FuturesMarketAsset.LDO]: FuturesMarketKey.sLDOPERP,
    [FuturesMarketAsset.ADA]: FuturesMarketKey.sADAPERP,
    [FuturesMarketAsset.GMX]: FuturesMarketKey.sGMXPERP,
    [FuturesMarketAsset.FIL]: FuturesMarketKey.sFILPERP,
    [FuturesMarketAsset.LTC]: FuturesMarketKey.sLTCPERP,
    [FuturesMarketAsset.BCH]: FuturesMarketKey.sBCHPERP,
    [FuturesMarketAsset.SHIB]: FuturesMarketKey.sSHIBPERP,
    [FuturesMarketAsset.CRV]: FuturesMarketKey.sCRVPERP,
    [FuturesMarketAsset.SUI]: FuturesMarketKey.sSUIPERP,
    [FuturesMarketAsset.PEPE]: FuturesMarketKey.sPEPEPERP,
    [FuturesMarketAsset.BLUR]: FuturesMarketKey.sBLURPERP,
    [FuturesMarketAsset.XRP]: FuturesMarketKey.sXRPPERP,
    [FuturesMarketAsset.DOT]: FuturesMarketKey.sDOTPERP,
    [FuturesMarketAsset.FLOKI]: FuturesMarketKey.sFLOKIPERP,
    [FuturesMarketAsset.INJ]: FuturesMarketKey.sINJPERP,
    [FuturesMarketAsset.TRX]: FuturesMarketKey.sTRXPERP,
    [FuturesMarketAsset.STETH]: FuturesMarketKey.sSTETHPERP,
    [FuturesMarketAsset.ETHBTC]: FuturesMarketKey.sETHBTCPERP,
    [FuturesMarketAsset.XMR]: FuturesMarketKey.sXMRPERP,
    [FuturesMarketAsset.MAV]: FuturesMarketKey.sMAVPERP,
    [FuturesMarketAsset.ETC]: FuturesMarketKey.sETCPERP,
    [FuturesMarketAsset.COMP]: FuturesMarketKey.sCOMPPERP,
    [FuturesMarketAsset.YFI]: FuturesMarketKey.sYFIPERP,
    [FuturesMarketAsset.MKR]: FuturesMarketKey.sMKRPERP,
    [FuturesMarketAsset.RPL]: FuturesMarketKey.sRPLPERP,
    [FuturesMarketAsset.WLD]: FuturesMarketKey.sWLDPERP,
    [FuturesMarketAsset.USDT]: FuturesMarketKey.sUSDTPERP,
    [FuturesMarketAsset.SEI]: FuturesMarketKey.sSEIPERP,
    [FuturesMarketAsset.RUNE]: FuturesMarketKey.sRUNEPERP,
    [FuturesMarketAsset.SUSHI]: FuturesMarketKey.sSUSHIPERP,
    [FuturesMarketAsset.ZEC]: FuturesMarketKey.sZECPERP,
    [FuturesMarketAsset.XTZ]: FuturesMarketKey.sXTZPERP,
    [FuturesMarketAsset.UMA]: FuturesMarketKey.sUMAPERP,
    [FuturesMarketAsset.ENJ]: FuturesMarketKey.sENJPERP,
    [FuturesMarketAsset.ICP]: FuturesMarketKey.sICPPERP,
    [FuturesMarketAsset.XLM]: FuturesMarketKey.sXLMPERP,
    [FuturesMarketAsset.ONEINCH]: FuturesMarketKey.s1INCHPERP,
    [FuturesMarketAsset.EOS]: FuturesMarketKey.sEOSPERP,
    [FuturesMarketAsset.CELO]: FuturesMarketKey.sCELOPERP,
    [FuturesMarketAsset.ALGO]: FuturesMarketKey.sALGOPERP,
    [FuturesMarketAsset.ZRX]: FuturesMarketKey.sZRXPERP,
    [FuturesMarketAsset.BAL]: FuturesMarketKey.sBALPERP,
    [FuturesMarketAsset.FXS]: FuturesMarketKey.sFXSPERP,
    [FuturesMarketAsset.KNC]: FuturesMarketKey.sKNCPERP,
    [FuturesMarketAsset.RNDR]: FuturesMarketKey.sRNDRPERP,
    [FuturesMarketAsset.ONE]: FuturesMarketKey.sONEPERP,
    [FuturesMarketAsset.PERP]: FuturesMarketKey.sPERPPERP,
    [FuturesMarketAsset.ZIL]: FuturesMarketKey.sZILPERP,
    [FuturesMarketAsset.STETHETH]: FuturesMarketKey.sSTETHETHPERP,
    [FuturesMarketAsset.SNX]: FuturesMarketKey.sSNXPERP,
    [FuturesMarketAsset.TIA]: FuturesMarketKey.sTIAPERP,
    [FuturesMarketAsset.IMX]: FuturesMarketKey.sIMXPERP,
    [FuturesMarketAsset.TRB]: FuturesMarketKey.sTRBPERP,
    [FuturesMarketAsset.MEME]: FuturesMarketKey.sMEMEPERP,
    [FuturesMarketAsset.ANKR]: FuturesMarketKey.sANKRPERP,
    [FuturesMarketAsset.FET]: FuturesMarketKey.sFETPERP,
    [FuturesMarketAsset.GRT]: FuturesMarketKey.sGRTPERP,
    [FuturesMarketAsset.PYTH]: FuturesMarketKey.sPYTHPERP,
    [FuturesMarketAsset.BONK]: FuturesMarketKey.sBONKPERP,
    [FuturesMarketAsset.JTO]: FuturesMarketKey.sJTOPERP,
    [FuturesMarketAsset.ORDI]: FuturesMarketKey.sORDIPERP,
    [FuturesMarketAsset.CVX]: FuturesMarketKey.sCVXPERP,
    [FuturesMarketAsset.JUP]: FuturesMarketKey.sJUPPERP,
    [FuturesMarketAsset.PENDLE]: FuturesMarketKey.sPENDLEPERP,
    [FuturesMarketAsset.STRK]: FuturesMarketKey.sSTRKPERP,
    [FuturesMarketAsset.W]: FuturesMarketKey.sWPERP,
    [FuturesMarketAsset.ENA]: FuturesMarketKey.sENAPERP,
    [FuturesMarketAsset.TON]: FuturesMarketKey.sTONPERP,
    [FuturesMarketAsset.ARKM]: FuturesMarketKey.sARKMPERP,
    [FuturesMarketAsset.GALA]: FuturesMarketKey.sGALAPERP,
    [FuturesMarketAsset.TAO]: FuturesMarketKey.sTAOPERP,
    [FuturesMarketAsset.BOME]: FuturesMarketKey.sBOMEPERP,
    [FuturesMarketAsset.ETHFI]: FuturesMarketKey.sETHFIPERP,
    [FuturesMarketAsset.STX]: FuturesMarketKey.sSTXPERP,
    [FuturesMarketAsset.AXL]: FuturesMarketKey.sAXLPERP,
    [FuturesMarketAsset['BTC²']]: FuturesMarketKey['sBTC²PERP'],
    [FuturesMarketAsset['ETH²']]: FuturesMarketKey['sETH²PERP'],
    [FuturesMarketAsset.RLB]: FuturesMarketKey.sRLBPERP,
    [FuturesMarketAsset.JPY]: FuturesMarketKey.sJPYPERP,
    [FuturesMarketAsset.MOG]: FuturesMarketKey.sMOGPERP,
    [FuturesMarketAsset.DOGE10]: FuturesMarketKey.sDOGE10PERP,
    [FuturesMarketAsset.MOG1M]: FuturesMarketKey.sMOG1MPERP,
    [FuturesMarketAsset.JPY1K]: FuturesMarketKey.sJPY1KPERP,
    [FuturesMarketAsset.AERO]: FuturesMarketKey.sAEROPERP,
    [FuturesMarketAsset.POPCAT]: FuturesMarketKey.sPOPCATPERP,
    [FuturesMarketAsset.MEEM]: FuturesMarketKey.sMEEMPERP,
    [FuturesMarketAsset.EIGEN]: FuturesMarketKey.sEIGENPERP,
    [FuturesMarketAsset.IO]: FuturesMarketKey.sIOPERP,
    [FuturesMarketAsset.MEW]: FuturesMarketKey.sMEWPERP,
    [FuturesMarketAsset.NOT]: FuturesMarketKey.sNOTPERP,
    [FuturesMarketAsset.PEOPLE]: FuturesMarketKey.sPEOPLEPERP,
    [FuturesMarketAsset.RENDER]: FuturesMarketKey.sRENDERPERP,
    [FuturesMarketAsset.SATS]: FuturesMarketKey.sSATSPERP,
    [FuturesMarketAsset.ZRO]: FuturesMarketKey.sZROPERP,
    [FuturesMarketAsset.GOAT]: FuturesMarketKey.sGOATPERP,
    [FuturesMarketAsset.MOODENG]: FuturesMarketKey.sMOODENGPERP,
    [FuturesMarketAsset.SAFE]: FuturesMarketKey.sSAFEPERP,
    [FuturesMarketAsset.CAT]: FuturesMarketKey.sCATPERP,
    [FuturesMarketAsset.PNUT]: FuturesMarketKey.sPNUTPERP,
    [FuturesMarketAsset.DEGEN]: FuturesMarketKey.sDEGENPERP,
    [FuturesMarketAsset.SLERF]: FuturesMarketKey.sSLERFPERP,
    [FuturesMarketAsset.MORPHO]: FuturesMarketKey.sMORPHOPERP,
    [FuturesMarketAsset.CHILLGUY]: FuturesMarketKey.sCHILLGUYPERP,
    [FuturesMarketAsset.SOLETH]: FuturesMarketKey.sSOLETHPERP,
    [FuturesMarketAsset.VIRTUAL]: FuturesMarketKey.sVIRTUALPERP,
};
export const AssetDisplayByAsset = {
    [FuturesMarketAsset.sBTC]: 'Bitcoin',
    [FuturesMarketAsset.WIF]: 'Dogwifhat',
    [FuturesMarketAsset.sETH]: 'Ethereum',
    [FuturesMarketAsset.LINK]: 'Chainlink',
    [FuturesMarketAsset.SOL]: 'Solana',
    [FuturesMarketAsset.AVAX]: 'Avalanche',
    [FuturesMarketAsset.AAVE]: 'Aave',
    [FuturesMarketAsset.UNI]: 'Uniswap',
    [FuturesMarketAsset.POL]: 'Polygon',
    [FuturesMarketAsset.XAU]: 'Gold',
    [FuturesMarketAsset.XAG]: 'Silver',
    [FuturesMarketAsset.EUR]: 'Euro',
    [FuturesMarketAsset.APE]: 'ApeCoin',
    [FuturesMarketAsset.DYDX]: 'DYDX',
    [FuturesMarketAsset.BNB]: 'Binance Coin',
    [FuturesMarketAsset.DOGE]: 'Dogecoin',
    [FuturesMarketAsset.OP]: 'Optimism',
    [FuturesMarketAsset.ARB]: 'Arbitrum',
    [FuturesMarketAsset.ATOM]: 'Cosmos',
    [FuturesMarketAsset.FTM]: 'Fantom',
    [FuturesMarketAsset.NEAR]: 'Near',
    [FuturesMarketAsset.FLOW]: 'Flow',
    [FuturesMarketAsset.AXS]: 'Axie Infinity',
    [FuturesMarketAsset.AUD]: 'Australian Dollar',
    [FuturesMarketAsset.GBP]: 'Pound Sterling',
    [FuturesMarketAsset.APT]: 'Aptos',
    [FuturesMarketAsset.LDO]: 'Lido',
    [FuturesMarketAsset.ADA]: 'Cardano',
    [FuturesMarketAsset.GMX]: 'GMX',
    [FuturesMarketAsset.FIL]: 'Filecoin',
    [FuturesMarketAsset.LTC]: 'Litecoin',
    [FuturesMarketAsset.BCH]: 'Bitcoin Cash',
    [FuturesMarketAsset.SHIB]: 'Shiba Inu',
    [FuturesMarketAsset.CRV]: 'Curve DAO',
    [FuturesMarketAsset.SUI]: 'Sui',
    [FuturesMarketAsset.PEPE]: 'Pepe',
    [FuturesMarketAsset.BLUR]: 'Blur',
    [FuturesMarketAsset.XRP]: 'XRP',
    [FuturesMarketAsset.DOT]: 'Polkadot',
    [FuturesMarketAsset.FLOKI]: 'Floki',
    [FuturesMarketAsset.INJ]: 'Injective',
    [FuturesMarketAsset.TRX]: 'Tron',
    [FuturesMarketAsset.STETH]: 'Lido Staked ETH',
    [FuturesMarketAsset.ETHBTC]: 'Ether/Bitcoin Ratio',
    [FuturesMarketAsset.XMR]: 'Monero',
    [FuturesMarketAsset.MAV]: 'Maverick',
    [FuturesMarketAsset.ETC]: 'Ethereum Classic',
    [FuturesMarketAsset.COMP]: 'Compound',
    [FuturesMarketAsset.YFI]: 'Yearn.Finance',
    [FuturesMarketAsset.MKR]: 'Maker',
    [FuturesMarketAsset.RPL]: 'Rocket Pool',
    [FuturesMarketAsset.WLD]: 'Worldcoin',
    [FuturesMarketAsset.USDT]: 'Tether',
    [FuturesMarketAsset.SEI]: 'Sei',
    [FuturesMarketAsset.RUNE]: 'THORChain',
    [FuturesMarketAsset.SUSHI]: 'Sushi',
    [FuturesMarketAsset.ZEC]: 'Zcash',
    [FuturesMarketAsset.XTZ]: 'Tezos',
    [FuturesMarketAsset.UMA]: 'UMA',
    [FuturesMarketAsset.ENJ]: 'Enjin',
    [FuturesMarketAsset.ICP]: 'Internet Computer',
    [FuturesMarketAsset.XLM]: 'Stellar',
    [FuturesMarketAsset.ONEINCH]: '1inch',
    [FuturesMarketAsset.EOS]: 'EOS',
    [FuturesMarketAsset.CELO]: 'Celo',
    [FuturesMarketAsset.ALGO]: 'Algorand',
    [FuturesMarketAsset.ZRX]: '0x',
    [FuturesMarketAsset.BAL]: 'Balancer',
    [FuturesMarketAsset.FXS]: 'Frax Share',
    [FuturesMarketAsset.KNC]: 'Kyber Network Crystal',
    [FuturesMarketAsset.RNDR]: 'Render',
    [FuturesMarketAsset.ONE]: 'One',
    [FuturesMarketAsset.PERP]: 'Perpetual Protocol',
    [FuturesMarketAsset.ZIL]: 'Zilliqa',
    [FuturesMarketAsset.STETHETH]: 'Lido stETH/ETH Ratio',
    [FuturesMarketAsset.SNX]: 'Synthetix',
    [FuturesMarketAsset.TIA]: 'Celestia',
    [FuturesMarketAsset.IMX]: 'Immutable',
    [FuturesMarketAsset.TRB]: 'Tellor Tributes',
    [FuturesMarketAsset.MEME]: 'Memecoin',
    [FuturesMarketAsset.ANKR]: 'Ankr Network',
    [FuturesMarketAsset.FET]: 'Fetch.ai',
    [FuturesMarketAsset.GRT]: 'The Graph',
    [FuturesMarketAsset.PYTH]: 'Pyth Network',
    [FuturesMarketAsset.BONK]: 'BONK',
    [FuturesMarketAsset.JTO]: 'Jito',
    [FuturesMarketAsset.ORDI]: 'ORDI',
    [FuturesMarketAsset.CVX]: 'Convex Finance',
    [FuturesMarketAsset.JUP]: 'Jupiter',
    [FuturesMarketAsset.PENDLE]: 'Pendle',
    [FuturesMarketAsset.STRK]: 'Starknet',
    [FuturesMarketAsset.W]: 'W',
    [FuturesMarketAsset.ENA]: 'ENA',
    [FuturesMarketAsset.TON]: 'Toncoin',
    [FuturesMarketAsset.ARKM]: 'Arkham',
    [FuturesMarketAsset.GALA]: 'Gala',
    [FuturesMarketAsset.TAO]: 'Bittensor',
    [FuturesMarketAsset.BOME]: 'Book of Meme',
    [FuturesMarketAsset.ETHFI]: 'Ether.fi',
    [FuturesMarketAsset.STX]: 'Stacks',
    [FuturesMarketAsset.AXL]: 'Axelar',
    [FuturesMarketAsset['BTC²']]: 'Bitcoin²',
    [FuturesMarketAsset['ETH²']]: 'Ethereum²',
    [FuturesMarketAsset.RLB]: 'Rollbit',
    [FuturesMarketAsset.JPY]: 'JPY',
    [FuturesMarketAsset.MOG]: 'Mog',
    [FuturesMarketAsset.DOGE10]: 'Dogecoin 10x',
    [FuturesMarketAsset.MOG1M]: 'Mog 1Mx',
    [FuturesMarketAsset.JPY1K]: 'JPY 1000x',
    [FuturesMarketAsset.AERO]: 'Aerodrome Finance',
    [FuturesMarketAsset.POPCAT]: 'Popcat',
    [FuturesMarketAsset.MEEM]: 'Meem',
    [FuturesMarketAsset.EIGEN]: 'Eigenlayer',
    [FuturesMarketAsset.IO]: 'IO',
    [FuturesMarketAsset.MEW]: 'Cat in a dogs world',
    [FuturesMarketAsset.NOT]: 'Not',
    [FuturesMarketAsset.PEOPLE]: 'ConstitutionDAO',
    [FuturesMarketAsset.RENDER]: 'Render',
    [FuturesMarketAsset.SATS]: 'Ordinals',
    [FuturesMarketAsset.ZRO]: 'LayerZero',
    [FuturesMarketAsset.SAFE]: 'Safe',
    [FuturesMarketAsset.GOAT]: 'Goatsesus Maximus',
    [FuturesMarketAsset.MOODENG]: 'Moo Deng',
    [FuturesMarketAsset.CAT]: "Simon's Cat",
    [FuturesMarketAsset.PNUT]: 'Peanut the Squirrel',
    [FuturesMarketAsset.DEGEN]: 'Degen',
    [FuturesMarketAsset.SLERF]: 'Slerf',
    [FuturesMarketAsset.MORPHO]: 'Morpho',
    [FuturesMarketAsset.CHILLGUY]: 'Just a chillguy',
    [FuturesMarketAsset.SOLETH]: 'Solana Ethereum Ratio',
    [FuturesMarketAsset.VIRTUAL]: 'Virtual Protocol',
};
// TODO: Update these types on the subgraph
export const formatPerpsV2Market = ({ market, key, asset, currentFundingRate, currentFundingVelocity, feeRates, marketDebt, marketSkew, maxLeverage, marketSize, price, }, marketParameters, globalSettings, isSuspended, suspendedReason, networkId) => {
    const marketSizeWei = weiFromWei(marketSize.toString());
    const marketSkewWei = weiFromWei(marketSkew.toString());
    const marketKey = hexToString(key, { size: 32 });
    const usdlimit = weiFromWei(marketParameters.maxMarketValue.toString()).mul(weiFromWei(price.toString()));
    const nativeLimit = weiFromWei(marketParameters.maxMarketValue.toString());
    return {
        provider: PerpsProvider.SNX_V2_OP,
        marketId: market,
        marginType: FuturesMarginType.ISOLATED_MARGIN,
        marketAddress: market,
        category: getMarketCategory(marketKey),
        marketName: getMarketName(hexToString(asset, { size: 32 })),
        asset: hexToString(asset, { size: 32 }),
        currentFundingRate: weiFromWei(currentFundingRate.toString()).div(24),
        currentFundingVelocity: weiFromWei(currentFundingVelocity.toString()).div(24 * 24),
        feeRates: {
            makerFee: weiFromWei(feeRates.makerFeeOffchainDelayedOrder.toString()),
            takerFee: weiFromWei(feeRates.takerFeeOffchainDelayedOrder.toString()),
        },
        openInterest: {
            shortPct: marketSizeWei.eq(0)
                ? 0
                : marketSizeWei.sub(marketSkewWei).div('2').div(marketSizeWei).toNumber(),
            longPct: marketSizeWei.eq(0)
                ? 0
                : marketSizeWei.add(marketSkewWei).div('2').div(marketSizeWei).toNumber(),
            shortUSD: marketSizeWei.eq(0)
                ? wei(0)
                : marketSizeWei.sub(marketSkewWei).div('2').mul(weiFromWei(price.toString())),
            longUSD: marketSizeWei.eq(0)
                ? wei(0)
                : marketSizeWei.add(marketSkewWei).div('2').mul(weiFromWei(price.toString())),
            long: marketSizeWei.add(marketSkewWei).div('2'),
            short: marketSizeWei.sub(marketSkewWei).div('2'),
        },
        marketDebt: weiFromWei(marketDebt.toString()),
        marketSkew: weiFromWei(marketSkew.toString()),
        contractMaxLeverage: weiFromWei(maxLeverage.toString()),
        appMaxLeverage: appAdjustedLeverage(wei(maxLeverage)),
        marketLimitUsd: {
            long: usdlimit,
            short: usdlimit,
        },
        marketLimitNative: {
            long: nativeLimit,
            short: nativeLimit,
        },
        minInitialMargin: globalSettings.minInitialMargin,
        settlementFee: globalSettings.minKeeperFee,
        isSuspended: isSuspended,
        marketClosureReason: suspendedReason,
        settings: {
            skewScale: weiFromWei(marketParameters.skewScale.toString()),
            delayedOrderConfirmWindow: wei(marketParameters.delayedOrderConfirmWindow.toString()).toNumber(),
            offchainDelayedOrderMinAge: wei(marketParameters.offchainDelayedOrderMinAge.toString()).toNumber(),
            offchainDelayedOrderMaxAge: wei(marketParameters.offchainDelayedOrderMaxAge.toString()).toNumber(),
            minDelayTimeDelta: wei(marketParameters.minDelayTimeDelta.toString()).toNumber(),
            maxDelayTimeDelta: wei(marketParameters.maxDelayTimeDelta.toString()).toNumber(),
            liquidationBufferRatio: weiFromWei(marketParameters.liquidationBufferRatio.toString()).toNumber(),
            liquidationPremiumMultiplier: weiFromWei(marketParameters.liquidationPremiumMultiplier.toString()).toNumber(),
            maxFundingVelocity: weiFromWei(marketParameters.maxFundingVelocity.toString()).toNumber(),
            maxLeverage: weiFromWei(marketParameters.maxLeverage.toString()).toNumber(),
            offchainPriceDivergence: weiFromWei(marketParameters.offchainPriceDivergence.toString()).toNumber(),
            maxLiquidationDelta: weiFromWei(marketParameters.maxLiquidationDelta.toString()).toNumber(),
            maxPD: weiFromWei(marketParameters.maxPD.toString()).toNumber(),
        },
        newListing: !!NEWLY_LISTED_MARKETS[networkId]?.includes(marketKey),
    };
};
export const sameSide = (a, b) => {
    return a.gt(wei(0)) === b.gt(wei(0));
};
export const getDecimalsForSwapDepositToken = (token) => {
    return ['USDT', 'USDC'].includes(token) ? 6 : 18;
};
export const getQuote = async (token, amountIn) => {
    const susdAddress = COMMON_ADDRESSES.SUSD['10'];
    const tokenAddress = COMMON_ADDRESSES[token]['10'];
    const decimals = getDecimalsForSwapDepositToken(token);
    const amountString = wei(amountIn, decimals).toString(0, true);
    const response = await axios.get(`${process.env.NEXT_PUBLIC_SERVICES_PROXY}/0x/swap/v1/quote`, {
        headers: {
            accept: 'application/json',
        },
        params: {
            buyToken: susdAddress,
            sellToken: tokenAddress,
            sellAmount: amountString,
            excludedSources: EXCLUDED_SOURCES.join(','),
        },
    });
    return wei(formatUnits(BigInt(response.data.buyAmount), 18));
};
const EXCLUDED_SOURCES = [
    'Native',
    'Uniswap',
    'Uniswap_V2',
    'Eth2Dai',
    'Kyber',
    'Curve',
    'LiquidityProvider',
    'MultiBridge',
    'Balancer',
    'Balancer_V2',
    'CREAM',
    'Bancor',
    'MakerPsm',
    'mStable',
    'Mooniswap',
    'MultiHop',
    'Shell',
    'Swerve',
    'SnowSwap',
    'SushiSwap',
    'DODO',
    'DODO_V2',
    'CryptoCom',
    'Linkswap',
    'KyberDMM',
    'Smoothy',
    'Component',
    'Saddle',
    'xSigma',
    // 'Uniswap_V3',
    'Curve_V2',
    'Lido',
    'ShibaSwap',
    'Clipper',
];
export const DepositTokenToSynth = {
    [PerpsProvider.SNX_V3_BASE]: {
        USDC: SynthAssetKeysV3.sUSDC,
    },
    [PerpsProvider.SNX_V3_ARB]: {
        ETH: SynthAssetKeysV3.sETH,
        WETH: SynthAssetKeysV3.sETH,
        USDx: SynthAssetKeysV3.USDx,
        USDC: SynthAssetKeysV3.sUSDC,
        tBTC: SynthAssetKeysV3.sBTC,
        USDe: SynthAssetKeysV3.sUSDe,
    },
};
export const SwappableDepositOptions = {
    [PerpsProvider.SNX_V3_BASE]: {
        USDC: SynthAssetKeysV3.USDx,
    },
    [PerpsProvider.SNX_V3_ARB]: {
        USDC: SynthAssetKeysV3.USDx,
    },
};
export const mapCollateralChanges = (collateralChange, chainId) => {
    return collateralChange.map(({ timestamp, sender, amountDelta, synthId, txHash }) => {
        const sizeWei = wei(amountDelta);
        const numTimestamp = wei(timestamp).toNumber();
        const key = v3SynthIdToKey(synthId, chainId);
        if (!key)
            throw new Error(`Invalid synth id ${synthId}`);
        return {
            timestamp: numTimestamp,
            account: sender,
            size: sizeWei.div(ETH_UNIT).toString(),
            action: sizeWei.gt(0) ? 'deposit' : 'withdraw',
            asset: key,
            txHash,
        };
    });
};
export const mapFuturesOrders = (orders) => {
    return orders
        .map((o) => {
        const marketKey = hexToString(o.marketKey, { size: 32 });
        const asset = MarketAssetByKey[marketKey];
        const size = weiFromWei(o.size);
        const isFullPosition = size.abs().eq(wei(maxInt256));
        const isMarketOrder = o.orderType === 'Market' || o.orderType === 'DelayedOffchain';
        const side = size.gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT;
        const triggerConditions = isMarketOrder || !o.targetPrice
            ? null
            : weiFromWei(o.targetPrice).mul(getPriceConditionDirection(o)).toString();
        const timestamp = wei(o.timestamp).mul(1000).toNumber();
        let executed = null;
        if (isMarketOrder && o.targetPrice) {
            executed = weiFromWei(o.targetPrice).toString();
        }
        if (!isMarketOrder && o.fillPrice) {
            executed = weiFromWei(o.fillPrice).toString();
        }
        return {
            id: o.id,
            asset: asset,
            account: o.account,
            sizeDelta: size.toString(),
            isFullPosition,
            displayAsset: getDisplayAsset(asset),
            txnHash: o.txnHash,
            orderType: getDisplayOrderType(o),
            status: o.status,
            side,
            triggerConditions,
            executed,
            isLiquidation: false,
            timestamp,
        };
    })
        .filter((o) => o.status !== 'Pending');
};
const getPriceConditionDirection = (order) => {
    const size = weiFromWei(order.size);
    if (size.gt(ZERO_WEI)) {
        return order.orderType === 'Limit' ? -1 : 1;
    }
    return order.orderType === 'Limit' ? 1 : -1;
};
const getDisplayOrderType = (order) => {
    let orderType = mapOrderType(order.orderType);
    if (orderType === 'Stop' || orderType === 'Limit') {
        orderType = formatOrderDisplayType(orderType === 'Limit' ? OrderTypeEnum.LIMIT : OrderTypeEnum.STOP);
    }
    return orderType;
};
export const mergeSnxV2PositionsData = (activePositions, positionsHistory, markets) => {
    return (positionsHistory?.reduce((acc, positionHistory) => {
        const activePosition = activePositions.find((pos) => pos.asset === positionHistory.asset && positionHistory.details.status === 'open');
        const market = markets.find((m) => m.asset === positionHistory.asset);
        const accruedFunding = wei(activePosition?.accruedFunding || ZERO_WEI).add(positionHistory.details.fees.netFunding);
        const pnl = positionHistory.details.pnl.rPnl.pnl;
        const netPnl = (activePosition?.accruedFunding || ZERO_WEI).add(positionHistory.details.pnl.rPnl.netPnl);
        const netPnlPct = netPnl.div(wei(positionHistory.details.margin.initialMargin).add(wei(positionHistory.details.stats.totalDeposits ?? 0)));
        const totalPnl = pnl.add(activePosition?.uPnl.pnl ?? wei(0));
        const totalNetPnl = netPnl.add(activePosition?.uPnl.pnl ?? wei(0));
        const totalNetPnlPct = totalNetPnl.div(wei(positionHistory.details.margin.initialMargin).add(wei(positionHistory.details.stats.totalDeposits ?? 0)));
        if (market) {
            const position = {
                provider: PerpsProvider.SNX_V2_OP,
                account: positionHistory.account,
                abstractAccount: positionHistory.abstractAccount,
                asset: market.asset,
                marketAddress: market.address,
                market,
                details: {
                    ...positionHistory.details,
                    side: activePosition?.side ?? positionHistory.details.side,
                    margin: {
                        initialMargin: positionHistory.details.margin.initialMargin,
                        initialLeverage: activePosition?.initialLeverage ?? positionHistory.details.margin.initialLeverage,
                        accessibleMargin: activePosition?.accessibleMargin ?? ZERO_WEI,
                        remainingMargin: activePosition?.remainingMargin ?? ZERO_WEI,
                        marginRatio: activePosition?.marginRatio ?? positionHistory.details.margin.marginRatio,
                        leverage: activePosition?.leverage ?? positionHistory.details.margin.leverage,
                        notionalValue: activePosition?.notionalValue ?? positionHistory.details.margin.notionalValue,
                    },
                    price: {
                        ...positionHistory.details.price,
                        liquidationPrice: activePosition?.liquidationPrice,
                        lastPrice: activePosition?.lastPrice,
                    },
                    pnl: {
                        uPnl: activePosition?.uPnl,
                        rPnl: {
                            pnl,
                            netPnl,
                            netPnlPct,
                        },
                        totalPnl: {
                            pnl: totalPnl,
                            netPnl: totalNetPnl,
                            netPnlPct: totalNetPnlPct,
                        },
                    },
                    fees: {
                        ...positionHistory.details.fees,
                        accruedFunding,
                    },
                    liquidation: {
                        ...positionHistory.details.liquidation,
                        canLiquidatePosition: !!activePosition?.canLiquidatePosition,
                    },
                },
            };
            acc.push(position);
        }
        return acc;
    }, []) ?? []);
};
