import { arbitrum, base, optimism } from '@account-kit/infra'
import { PerpsProvider, SnxV2NetworkIds, SnxV3NetworkIds } from '@kwenta/sdk/types'

import SNXIcon from 'assets/svg/partners/snx.svg'
import ArbitrumIcon from 'assets/svg/providers/arbitrum.svg'
import BaseIcon from 'assets/svg/providers/base.svg'
import OptimismIcon from 'assets/svg/providers/optimism.svg'

import { DEFAULT_PERPS_PROVIDER } from './futures'

export const GWEI_UNIT = 1000000000
export const GWEI_DECIMALS = 9
export const ETH_UNIT = 1000000000000000000
export const STALL_TIMEOUT = 5000
export const DEFAULT_SESSION_MS = 60 * 60 * 24 * 1000

export const SUPPORTED_CHAIN_IDS_MAINNET = [
	1, // Ethereum (mainnet)
	SnxV2NetworkIds.OPTIMISM_MAINNET,
	SnxV3NetworkIds.BASE_MAINNET,
	SnxV3NetworkIds.ARB_MAINNET,
]

export const SUPPORTED_WATCHER_MODE_NETWORKS = [1, 10, 8453]

export const SUPPORTED_CHAIN_IDS = [
	SnxV2NetworkIds.OPTIMISM_MAINNET,
	SnxV2NetworkIds.OPTIMISM_SEPOLIA,
	SnxV3NetworkIds.BASE_MAINNET,
	SnxV3NetworkIds.BASE_SEPOLIA,
	SnxV3NetworkIds.ARB_MAINNET,
	SnxV3NetworkIds.ARB_SEPOLIA,
	1,
]

export const PROVIDER_MAINNET_CHAINS = {
	[PerpsProvider.SNX_V3_BASE]: base,
	[PerpsProvider.SNX_V2_OP]: optimism,
	[PerpsProvider.SNX_V3_ARB]: arbitrum,
}

export const PROVIDER_CHAIN_NAMES = {
	[PerpsProvider.SNX_V3_BASE]: 'base',
	[PerpsProvider.SNX_V2_OP]: 'optimism',
	[PerpsProvider.SNX_V3_ARB]: 'arbitrum',
} as const

export const PROVIDER_CHAIN_TICKERS = {
	[PerpsProvider.SNX_V3_BASE]: 'BASE',
	[PerpsProvider.SNX_V2_OP]: 'OP',
	[PerpsProvider.SNX_V3_ARB]: 'ARB',
}

export const PROVIDER_NAMES = {
	[PerpsProvider.SNX_V3_BASE]: 'Synthetix',
	[PerpsProvider.SNX_V2_OP]: 'Synthetix',
	[PerpsProvider.SNX_V3_ARB]: 'Synthetix',
}

export const PROVIDER_CHAIN_ICONS = {
	[PerpsProvider.SNX_V2_OP]: <OptimismIcon width={10} height={10} />,
	[PerpsProvider.SNX_V3_BASE]: <BaseIcon width={11.5} height={11.5} />,
	[PerpsProvider.SNX_V3_ARB]: <ArbitrumIcon width={11} height={11} />,
}

export const PROVIDER_NAMES_VERSION = {
	[PerpsProvider.SNX_V3_BASE]: 'Synthetix v3',
	[PerpsProvider.SNX_V2_OP]: 'Synthetix v2',
	[PerpsProvider.SNX_V3_ARB]: 'Synthetix v3',
}

export const providerChainIcon = (
	provider: PerpsProvider,
	props: { width: number; height: number }
) => {
	switch (provider) {
		case PerpsProvider.SNX_V2_OP:
			return <OptimismIcon {...props} />
		case PerpsProvider.SNX_V3_BASE:
			return <BaseIcon {...props} />
		case PerpsProvider.SNX_V3_ARB:
			return <ArbitrumIcon {...props} />
	}
}

export const providerIcon = (provider: PerpsProvider, props: { width: number; height: number }) => {
	switch (provider) {
		case PerpsProvider.SNX_V2_OP:
			return <SNXIcon {...props} />
		case PerpsProvider.SNX_V3_BASE:
			return <SNXIcon {...props} />
		case PerpsProvider.SNX_V3_ARB:
			return <SNXIcon {...props} />
	}
}

export const DEFAULT_CHAIN = PROVIDER_MAINNET_CHAINS[DEFAULT_PERPS_PROVIDER]

export const providerFromChainId = (chainId: number) => {
	switch (chainId) {
		case SnxV2NetworkIds.OPTIMISM_MAINNET:
		case SnxV2NetworkIds.OPTIMISM_SEPOLIA:
			return [PerpsProvider.SNX_V2_OP]
		case SnxV3NetworkIds.BASE_MAINNET:
		case SnxV3NetworkIds.BASE_SEPOLIA:
			return [PerpsProvider.SNX_V3_BASE]
		case SnxV3NetworkIds.ARB_MAINNET:
		case SnxV3NetworkIds.ARB_SEPOLIA:
			return [PerpsProvider.SNX_V3_ARB]
		default:
			return [DEFAULT_PERPS_PROVIDER]
	}
}
