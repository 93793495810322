import { wei } from '@kwenta/wei';
import { PERPS_V3_SUBGRAPH_URLS, SYNTHETIX_TRACKING_CODE, ZERO_WEI, v3PerpsMarketIdToAssetKey, } from '../constants';
import { FuturesMarginType, OrderTypeEnum, PerpsProvider, PositionSide, RawCondition, SnxV3NetworkIds, } from '../types';
import { encodeFunctionData, maxInt128 } from 'viem';
import MarginEngineAbi from '../contracts/abis/MarginEngine.js';
import { formatOrderDisplayType, getDisplayAsset } from './futures';
import { weiFromWei } from './number';
export const getPerpsV3SubgraphUrl = (networkId) => {
    return PERPS_V3_SUBGRAPH_URLS[networkId] ?? PERPS_V3_SUBGRAPH_URLS[SnxV3NetworkIds.BASE_MAINNET];
};
export const serializeConditionalOrder = (order) => {
    return {
        orderDetails: {
            marketId: Number(order.orderDetails.marketId.toString()),
            networkId: Number(order.orderDetails.networkId.toString()),
            accountId: order.orderDetails.accountId.toString(),
            sizeDelta: order.orderDetails.sizeDelta.toString(),
            settlementStrategyId: Number(order.orderDetails.settlementStrategyId.toString()),
            acceptablePrice: order.orderDetails.acceptablePrice.toString(),
            isReduceOnly: order.orderDetails.isReduceOnly,
            trackingCode: order.orderDetails.trackingCode,
            referrer: order.orderDetails.referrer,
        },
        signer: order.signer,
        nonce: Number(order.nonce.toString()),
        requireVerified: order.requireVerified,
        trustedExecutor: order.trustedExecutor,
        maxExecutorFee: order.maxExecutorFee.toString(),
        conditions: order.conditions,
    };
};
export const parseConditionArgs = (orderDetails, condition, value) => {
    switch (condition) {
        case RawCondition.IsTimestampAfter:
        case RawCondition.IsTimestampBefore:
            return [value];
        case RawCondition.IsPriceAbove:
        case RawCondition.IsPriceBelow:
            return [SYNTHETIX_TRACKING_CODE, value, BigInt(0)]; // Update this
        case RawCondition.IsPositionSizeAbove:
        case RawCondition.IsPositionSizeBelow:
            return [orderDetails.accountId, orderDetails.marketId, orderDetails.sizeDelta];
        case RawCondition.IsOrderFeeBelow:
            return [orderDetails.marketId, orderDetails.sizeDelta, BigInt(0)];
        case RawCondition.IsMarketOpen:
            return [];
        default:
            throw new Error(`Invalid condition: ${condition}`);
    }
};
export const orderConditionsToCallData = (orderDetails, rawConditions) => {
    return Object.entries(rawConditions).map(([condition, value]) => {
        return encodeFunctionData({
            abi: MarginEngineAbi,
            functionName: condition,
            args: parseConditionArgs(orderDetails, condition, value),
        });
    });
};
export const calculatePerpsV3Volumes = (futuresHourlyStats) => {
    const volumes = futuresHourlyStats.reduce((acc, { marketSymbol, volume, trades }) => {
        acc[marketSymbol] = {
            volume: weiFromWei(volume).add(acc[marketSymbol]?.volume ?? 0),
            trades: weiFromWei(trades).add(acc[marketSymbol]?.trades ?? 0),
        };
        return acc;
    }, {});
    return volumes;
};
export const orderTypeFromCondition = (positionSide, order) => {
    if (!positionSide)
        return OrderTypeEnum.LIMIT;
    if (positionSide === PositionSide.LONG) {
        if (Number(order.orderDetails.sizeDelta) > 0 && order.decodedConditions.isPriceBelow) {
            return OrderTypeEnum.LIMIT;
        }
        else if (Number(order.orderDetails.sizeDelta) < 0 && order.decodedConditions.isPriceAbove) {
            return OrderTypeEnum.TAKE_PROFIT;
        }
        else if (Number(order.orderDetails.sizeDelta) < 0 && order.decodedConditions.isPriceBelow) {
            return OrderTypeEnum.STOP_LOSS;
        }
        else {
            return OrderTypeEnum.STOP;
        }
    }
    else {
        if (Number(order.orderDetails.sizeDelta) < 0 && order.decodedConditions.isPriceAbove) {
            return OrderTypeEnum.LIMIT;
        }
        else if (Number(order.orderDetails.sizeDelta) > 0 && order.decodedConditions.isPriceBelow) {
            return OrderTypeEnum.TAKE_PROFIT;
        }
        else if (Number(order.orderDetails.sizeDelta) > 0 && order.decodedConditions.isPriceAbove) {
            return OrderTypeEnum.STOP_LOSS;
        }
        else {
            return OrderTypeEnum.STOP;
        }
    }
};
export const reconcileOrders = (chainId, ordersSettled, conditionalOrders) => {
    const filledOrders = ordersSettled.map((orderSettled) => {
        const asset = v3PerpsMarketIdToAssetKey(chainId, orderSettled.marketId);
        const sizeDelta = weiFromWei(orderSettled.sizeDelta);
        const side = sizeDelta.gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT;
        const executed = weiFromWei(orderSettled.fillPrice).toString();
        const timestamp = wei(orderSettled.timestamp).mul(1000).toNumber();
        const isFullPosition = sizeDelta.abs().gte(wei(maxInt128));
        const conditionalOrder = conditionalOrders.find((co) => co.txnHash === orderSettled.orderCommitted.txnHash);
        let triggerConditions = null;
        let orderType = 'Market';
        const txnHash = orderSettled.txnHash ?? null;
        if (conditionalOrder) {
            const isPriceBelow = conditionalOrder.decodedConditions.isPriceBelow;
            const isPriceAbove = conditionalOrder.decodedConditions.isPriceAbove;
            const price = isPriceBelow ?? isPriceAbove;
            triggerConditions = !price
                ? null
                : wei(price)
                    .mul(isPriceBelow ? -1 : 1)
                    .toString();
            orderType = formatOrderDisplayType(orderTypeFromCondition(side, conditionalOrder));
        }
        else {
            triggerConditions = null;
        }
        return {
            id: orderSettled.id,
            asset: asset,
            sizeDelta: sizeDelta.toString(),
            isFullPosition,
            displayAsset: getDisplayAsset(asset),
            txnHash,
            orderType,
            status: 'Filled',
            side,
            triggerConditions,
            executed,
            isLiquidation: false,
            timestamp,
        };
    });
    const cancelledOrFailedOrders = conditionalOrders
        .filter((co) => co.status !== 'Executed' && co.status !== 'Pending')
        .map((conditionalOrder) => {
        const asset = v3PerpsMarketIdToAssetKey(chainId, conditionalOrder.orderDetails.marketId);
        const sizeDelta = wei(conditionalOrder.orderDetails.sizeDelta);
        const side = sizeDelta.gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT;
        const timestamp = new Date(conditionalOrder.updatedAt).getTime();
        const isFullPosition = sizeDelta.abs().gte(wei(maxInt128));
        const orderType = formatOrderDisplayType(orderTypeFromCondition(side, conditionalOrder));
        const isPriceBelow = conditionalOrder.decodedConditions.isPriceBelow;
        const isPriceAbove = conditionalOrder.decodedConditions.isPriceAbove;
        const price = isPriceBelow ?? isPriceAbove;
        const triggerConditions = !price
            ? null
            : wei(price)
                .mul(isPriceBelow ? -1 : 1)
                .toString();
        return {
            id: String(conditionalOrder.id),
            asset: asset,
            sizeDelta: sizeDelta.toString(),
            isFullPosition,
            displayAsset: getDisplayAsset(asset),
            txnHash: null,
            orderType,
            status: conditionalOrder.status,
            side,
            triggerConditions,
            executed: null,
            isLiquidation: false,
            timestamp,
        };
    });
    return [...filledOrders, ...cancelledOrFailedOrders].sort((a, b) => b.timestamp - a.timestamp);
};
export const orderSettledToTrade = (chainId, orderSettleds) => {
    return orderSettleds.map((o) => {
        const pnl = weiFromWei(o.pnl);
        const pnlWithFeesPaid = pnl
            .sub(weiFromWei(o.totalFees))
            .add(weiFromWei(o.accruedFunding))
            .add(weiFromWei(o.interestCharged ?? 0));
        return {
            ...o,
            account: o.account.owner,
            asset: v3PerpsMarketIdToAssetKey(chainId, o.marketId),
            timestamp: Number(o.timestamp),
            fillPrice: weiFromWei(o.fillPrice),
            sizeDelta: weiFromWei(o.sizeDelta),
            interestCharged: o.interestCharged ? weiFromWei(o.interestCharged) : undefined,
            totalFees: weiFromWei(o.totalFees),
            fundingAccrued: weiFromWei(o.accruedFunding),
            side: Number(o.sizeDelta) > 0 ? PositionSide.LONG : PositionSide.SHORT,
            pnl: weiFromWei(o.pnl),
            pnlWithFeesPaid: pnlWithFeesPaid,
            ownerAddress: o.account.owner,
            settlementTxHash: o.txnHash,
            commitmentTxHash: o.orderCommitted.txnHash,
            orderType: 'Market',
            positionId: o.position?.id,
        };
    });
};
export const formatV3AsyncOrder = (order) => {
    const { accountId, marketId, sizeDelta, settlementStrategyId, acceptablePrice } = order.request;
    return {
        accountId: accountId.toString(),
        marketId: marketId.toString(),
        sizeDelta: wei(sizeDelta),
        settlementTime: Number(order.commitmentTime),
        settlementStrategyId: Number(settlementStrategyId),
        acceptablePrice: wei(acceptablePrice),
        side: wei(sizeDelta).gt(0) ? PositionSide.LONG : PositionSide.SHORT,
    };
};
export const mapPerpsV3Position = (chainId, marketId, pnl, funding, size, owedInterest) => {
    const pnlWei = weiFromWei(pnl.toString());
    const pnlPct = wei(0); // TODO: [PERPS_V3] Calculate PNL %
    return wei(size).eq(ZERO_WEI)
        ? null
        : {
            provider: PerpsProvider.SNX_V3_BASE,
            marketId: marketId.toString(),
            asset: v3PerpsMarketIdToAssetKey(chainId, marketId.toString()),
            side: weiFromWei(size.toString()).gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT,
            accruedFunding: weiFromWei(funding.toString()),
            owedInterest: weiFromWei(owedInterest?.toString() ?? 0).neg(),
            size: weiFromWei(size.toString()).abs(),
            pnl: pnlWei,
            pnlPct,
        };
};
export const mergeSnxV3PositionsData = (futuresPositions, activePositions, marginInfo, futuresMarkets, markPrices) => {
    const { availableMargin, requiredMaintenanceMargin } = marginInfo;
    return futuresPositions.reduce((acc, positionHistory) => {
        const avgEntryPrice = weiFromWei(positionHistory.avgEntryPrice);
        const entryPrice = weiFromWei(positionHistory.entryPrice);
        const futuresMarket = futuresMarkets.find((fm) => fm.id.toString() === positionHistory.marketId);
        if (!futuresMarket)
            return acc;
        const marketAsset = futuresMarket?.asset;
        if (!marketAsset) {
            return acc;
        }
        const activePosition = activePositions.find((ap) => {
            return ap.asset === marketAsset && positionHistory.isOpen;
        });
        const aggregatedSize = weiFromWei(positionHistory.trades.slice(0, -1).reduce((acc, curr) => acc.add(curr.sizeDelta), ZERO_WEI));
        const exitPrice = positionHistory.exitPrice ? weiFromWei(positionHistory.exitPrice) : null;
        const side = activePosition
            ? activePosition.side
            : aggregatedSize.gte(wei(0))
                ? PositionSide.LONG
                : PositionSide.SHORT;
        const markPrice = markPrices[marketAsset] ?? wei(0);
        const rPnl = weiFromWei(positionHistory.realizedPnl);
        const netPnl = weiFromWei(positionHistory.pnlWithFeesPaid).add(wei(activePosition?.accruedFunding ?? ZERO_WEI));
        const uPnl = activePosition
            ? activePosition.side === PositionSide.LONG
                ? weiFromWei(activePosition.size).mul(weiFromWei(avgEntryPrice).sub(markPrice))
                : weiFromWei(activePosition.size).mul(weiFromWei(avgEntryPrice).sub(markPrice)).neg()
            : null;
        const liqPrice = activePosition
            ? requiredMaintenanceMargin
                .sub(availableMargin)
                .div(activePosition.side === PositionSide.LONG
                ? activePosition.size
                : activePosition.size.neg())
                .add(markPrice)
            : undefined;
        const startNotional = activePosition?.size.abs().mul(avgEntryPrice ?? 0);
        const currentNotional = activePosition?.size.abs().mul(markPrice);
        const uPnlPct = activePosition && startNotional && currentNotional
            ? startNotional.gt(0)
                ? activePosition.side === PositionSide.LONG
                    ? currentNotional.sub(startNotional).div(startNotional)
                    : currentNotional.sub(startNotional).div(startNotional.neg())
                : wei(0)
            : null;
        const accruedFunding = weiFromWei(positionHistory.netFunding ?? ZERO_WEI).add(weiFromWei(activePosition?.accruedFunding ?? ZERO_WEI));
        const leverage = currentNotional && marginInfo.availableMargin.gt(0)
            ? currentNotional.div(marginInfo.availableMargin)
            : wei(0);
        const position = {
            provider: PerpsProvider.SNX_V3_BASE,
            account: positionHistory.account,
            asset: futuresMarket.asset,
            marketId: positionHistory.marketId,
            details: {
                id: positionHistory.id,
                side,
                size: weiFromWei(positionHistory.size).abs(),
                status: (positionHistory.isOpen
                    ? 'open'
                    : positionHistory.isLiquidated
                        ? 'liquidated'
                        : 'closed'),
                accountType: FuturesMarginType.CROSS_MARGIN,
                timestamp: wei(positionHistory.timestamp).mul(1000).toNumber(),
                openTimestamp: wei(positionHistory.openTimestamp).mul(1000).toNumber(),
                closeTimestamp: positionHistory.closeTimestamp
                    ? wei(positionHistory.closeTimestamp).mul(1000).toNumber()
                    : undefined,
                transactionHash: 'todo?',
                stats: {
                    totalVolume: weiFromWei(positionHistory.totalVolume),
                    trades: positionHistory.trades.length + (positionHistory.isLiquidated ? 1 : 0),
                },
                margin: {
                    accessibleMargin: wei(0),
                    remainingMargin: wei(0),
                    initialMargin: wei(0),
                    marginRatio: wei(0),
                    initialLeverage: wei(0),
                    leverage,
                    notionalValue: activePosition?.size.mul(markPrice) ?? ZERO_WEI,
                },
                price: {
                    entryPrice,
                    avgEntryPrice,
                    exitPrice,
                    liquidationPrice: liqPrice?.lt(0) ? wei(0) : liqPrice,
                    lastPrice: activePosition ? markPrice : weiFromWei(positionHistory.lastPrice),
                },
                pnl: {
                    uPnl: uPnl && uPnlPct ? { pnl: uPnl, pnlPct: uPnlPct } : undefined,
                    rPnl: {
                        pnl: rPnl,
                        netPnl: netPnl,
                        netPnlPct: wei(0),
                    },
                    totalPnl: {
                        pnl: rPnl.add(uPnl ?? wei(0)),
                        netPnl: netPnl.add(uPnl ?? wei(0)),
                        netPnlPct: wei(0),
                    },
                },
                fees: {
                    accruedFunding,
                    owedInterest: weiFromWei(positionHistory.interestCharged),
                    feesPaid: weiFromWei(positionHistory.feesPaid),
                    netFunding: weiFromWei(positionHistory.netFunding),
                },
                liquidation: {
                    isLiquidated: positionHistory.isLiquidated,
                },
            },
        };
        acc.push(position);
        return acc;
    }, []);
};
export const formatSettlementStrategy = (strategy) => {
    return {
        ...strategy,
        marketId: strategy.marketId,
        strategyId: Number(strategy.strategyId),
        settlementDelay: wei(strategy.settlementDelay),
        settlementWindowDuration: wei(strategy.settlementWindowDuration),
        settlementReward: weiFromWei(strategy.settlementReward),
    };
};
export const chainToV3Provider = (chainId) => {
    switch (chainId) {
        case SnxV3NetworkIds.BASE_SEPOLIA:
        case SnxV3NetworkIds.BASE_MAINNET:
            return PerpsProvider.SNX_V3_BASE;
        case SnxV3NetworkIds.ARB_SEPOLIA:
            return PerpsProvider.SNX_V3_ARB;
        case SnxV3NetworkIds.ARB_MAINNET:
            return PerpsProvider.SNX_V3_ARB;
        default:
            throw new Error(`Invalid chainId: ${chainId}`);
    }
};
export const chainIsMultiCollateral = (chainId) => {
    return chainId === SnxV3NetworkIds.ARB_SEPOLIA || chainId === SnxV3NetworkIds.ARB_MAINNET;
};
export const mergeSnxV3LiquidationTrades = (chainId, trades, liquidationPositions) => {
    const liquidationTrades = liquidationPositions.map((liquidationPosition) => ({
        id: liquidationPosition.id,
        asset: v3PerpsMarketIdToAssetKey(chainId, liquidationPosition.marketId),
        marketId: liquidationPosition.marketId,
        account: liquidationPosition.accountId,
        ownerAddress: liquidationPosition.accountOwner,
        side: null,
        fillPrice: weiFromWei(liquidationPosition.estimatedPrice),
        sizeDelta: weiFromWei(liquidationPosition.amount),
        timestamp: Number(liquidationPosition.timestamp),
        settlementTxHash: liquidationPosition.txHash,
        orderType: 'Liquidation',
        pnl: weiFromWei(liquidationPosition.liquidationPnl),
        pnlWithFeesPaid: weiFromWei(liquidationPosition.liquidationPnl),
        totalFees: ZERO_WEI,
        fundingAccrued: ZERO_WEI, // TODO: Waiting fix by SNX
        txnHash: liquidationPosition.txHash,
    }));
    return [...liquidationTrades, ...trades].sort((a, b) => b.timestamp - a.timestamp);
};
