import { PerpsProvider } from '@kwenta/sdk/types'
import { AccountAbstraction as AlchemyAA } from 'services/alchemy/accountAbstraction'
import { AccountAbstraction as BiconomyAA } from 'services/biconomy/accountAbstraction'
import { LocalStorage } from 'services/storage/localStorage'
import type { AbstractAccountAbstraction } from 'types/accountAbstraction'

export class AccountAbstractionFactory {
	private biconomyAA: AbstractAccountAbstraction
	private alchemyAA: AbstractAccountAbstraction

	constructor() {
		this.biconomyAA = new BiconomyAA(new LocalStorage())
		this.alchemyAA = new AlchemyAA(new LocalStorage())
	}

	getAccountAbstraction(provider: PerpsProvider) {
		switch (provider) {
			case PerpsProvider.SNX_V3_ARB:
				return this.alchemyAA
			case PerpsProvider.SNX_V3_BASE:
			case PerpsProvider.SNX_V2_OP:
				return this.biconomyAA

			default:
				return null
		}
	}
}

const accountAbstractionFactory = new AccountAbstractionFactory()

export { accountAbstractionFactory }
