import { fromUnixTime, isValid } from 'date-fns'
import type { TFunction } from 'i18next'

import { NO_VALUE } from 'constants/placeholder'

export const INTERVAL_OPTIONS = ['1H', '6H', '12H', '1D', '1W', '1M'] as const

export const ONE_SECOND_IN_MS = 1000
export const ONE_MINUTE_IN_MS = 60000

export const SECOND_INTERVALS = {
	3600: '1H',
	21600: '6H',
	43200: '12H',
	86400: '1D',
	604800: '1W',
	2628000: '1M',
} as const

export type DateInterval = (typeof INTERVAL_OPTIONS)[number]

export function convertIntervalToSeconds(interval: DateInterval): number {
	const entries = Object.entries(SECOND_INTERVALS)
	const findedEntry = entries.find(([_, label]) => interval === label)
	return findedEntry ? Number.parseFloat(findedEntry[0]) : 0
}

export function convertSecondsToExpiryDate(interval: DateInterval): string {
	const seconds = convertIntervalToSeconds(interval)
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const secondsLeft = seconds % 60
	const hoursString = hours.toString().padStart(2, '0')
	const minutesString = minutes.toString().padStart(2, '0')
	const secondsString = secondsLeft.toString().padStart(2, '0')
	return `${hoursString}:${minutesString}:${secondsString}`
}

export function convertSecondsToInterval(seconds: number): DateInterval {
	const entries = Object.entries(SECOND_INTERVALS)
	const findedEntry = entries.find(([secs, _]) => Number.parseFloat(secs) === seconds)
	return findedEntry ? findedEntry[1] : '1D'
}

export const safeFromUnixtime = (timestamp: string | null): Date | null => {
	if (!timestamp) return null
	const date = fromUnixTime(Number(timestamp))
	return isValid(date) ? date : null
}

export const calculateTimeDelta = (time: number, t: TFunction) => {
	const timeDelta = (Date.now() - time * 1000) / 1000

	if (timeDelta === 0) {
		return NO_VALUE
	} else if (timeDelta < 60) {
		// less than 1m
		return `${t('common.time.n-sec-ago', { timeDelta: Math.floor(timeDelta) })}`
	} else if (timeDelta < 3600) {
		// less than 1h
		return `${t('common.time.n-min-ago', { timeDelta: Math.floor(timeDelta / 60) })}`
	} else if (timeDelta < 86400) {
		// less than 1d
		return `${t('common.time.n-hr-ago', { timeDelta: Math.floor(timeDelta / 3600) })}`
	} else {
		// greater than 1d
		return `${t('common.time.n-day-ago', {
			timeDelta: Math.floor(timeDelta / 86400),
		})}`
	}
}
