import { EPOCH_CONFIGS, EPOCH_START, WEEK, ZERO_WEI } from '@kwenta/sdk/constants'
import { PerpsProvider, SnxV2NetworkIds, TransactionStatus } from '@kwenta/sdk/types'
import { getEpochDetails, getEpochPeriod } from '@kwenta/sdk/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Address } from 'viem/accounts'

import { monitorTransaction } from 'contexts/RelayerContext'
import { monitorAndAwaitTransaction } from 'state/app/helpers'
import { handleTransactionError, setOpenModal, setTransaction } from 'state/app/reducer'
import { fetchPerpsAccounts } from 'state/futures/actions'
import { selectSnxPerpsV2Network } from 'state/futures/common/selectors'
import { selectEpochPeriod, selectStakingSupportedNetwork } from 'state/staking/selectors'
import {
	fetchEscrowMigratorAllowance,
	fetchMigrationDeadline,
	fetchRegisteredVestingEntryIDs,
	fetchToPay,
	fetchTotalEscrowUnmigrated,
	fetchUnmigratedRegisteredEntryIDs,
	fetchUnregisteredVestingEntryIDs,
	fetchUnvestedRegisteredEntryIDs,
} from 'state/stakingMigration/actions'
import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import {
	ZERO_CLAIMABLE_REWARDS,
	ZERO_CLAIMABLE_REWARDS_PARAMS,
	ZERO_ESCROW_BALANCE,
	ZERO_ESTIMATED_REWARDS,
	ZERO_FEES_PAID,
	ZERO_REFERRAL_FEES_PAID,
	ZERO_STAKING_DATA,
	ZERO_STAKING_V2_DATA,
} from './reducer'
import type {
	ClaimableRewards,
	ClaimableRewardsParams,
	EscrowBalance,
	EstimatedRewards,
	FeesPaid,
	ReferralFeesPaid,
	StakingAction,
	StakingActionV2,
	TransferEscrowEntriesInput,
	TransferEscrowEntryInput,
} from './types'
import { serializeClaimParams, unserializeClaimParams } from './utils'

export const fetchStakingData = createAsyncThunk<StakingAction, void, ThunkConfig>(
	'staking/fetchStakingData',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const epochPeriod = Math.floor((Math.floor(Date.now() / 1000) - EPOCH_START[10]) / WEEK)
			const wallet = selectWallet(getState())
			if (!wallet)
				return {
					...ZERO_STAKING_DATA,
					epochPeriod,
				}

			const {
				rewardEscrowBalance,
				stakedNonEscrowedBalance,
				stakedEscrowedBalance,
				claimableBalance,
				kwentaBalance,
				weekCounter,
				totalStakedBalance,
				vKwentaBalance,
				vKwentaAllowance,
				kwentaAllowance,
				veKwentaBalance,
				veKwentaAllowance,
			} = await sdk.kwentaToken.getStakingData()

			return {
				escrowedKwentaBalance: rewardEscrowBalance.toString(),
				stakedKwentaBalance: stakedNonEscrowedBalance.toString(),
				stakedEscrowedKwentaBalance: stakedEscrowedBalance.toString(),
				claimableBalance: claimableBalance.toString(),
				kwentaBalance: kwentaBalance.toString(),
				weekCounter,
				totalStakedBalance: totalStakedBalance.toString(),
				vKwentaBalance: vKwentaBalance.toString(),
				vKwentaAllowance: vKwentaAllowance.toString(),
				kwentaAllowance: kwentaAllowance.toString(),
				epochPeriod,
				veKwentaBalance: veKwentaBalance.toString(),
				veKwentaAllowance: veKwentaAllowance.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchStakingV2Data = createAsyncThunk<StakingActionV2, void, ThunkConfig>(
	'staking/fetchStakingDataV2',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_STAKING_V2_DATA

			const {
				rewardEscrowBalance,
				stakedNonEscrowedBalance,
				stakedEscrowedBalance,
				claimableBalance,
				feeShareBalance,
				totalStakedBalance,
				stakedResetTime,
				kwentaStakingV2Allowance,
			} = await sdk.kwentaToken.getStakingV2Data()

			return {
				escrowedKwentaBalance: rewardEscrowBalance.toString(),
				stakedKwentaBalance: stakedNonEscrowedBalance.toString(),
				stakedEscrowedKwentaBalance: stakedEscrowedBalance.toString(),
				claimableBalance: claimableBalance.toString(),
				feeShareBalance: feeShareBalance.toString(),
				totalStakedBalance: totalStakedBalance.toString(),
				stakedResetTime,
				kwentaStakingV2Allowance: kwentaStakingV2Allowance.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const approveKwentaToken = createAsyncThunk<
	void,
	'kwenta' | 'vKwenta' | 'veKwenta' | 'kwentaStakingV2',
	ThunkConfig
>('staking/approveKwentaToken', async (token, { dispatch, extra: { sdk } }) => {
	try {
		dispatch(
			setTransaction({
				chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
				status: TransactionStatus.AwaitingExecution,
				type: 'claim_kwenta_rewards',
				hash: null,
			})
		)

		const tx = await sdk.kwentaToken.approveKwentaToken(token)

		await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
		dispatch(fetchStakeMigrateData())
	} catch (err) {
		dispatch(handleTransactionError({ message: err.message }))
		throw err
	}
})

export const redeemToken = createAsyncThunk<void, 'vKwenta' | 'veKwenta', ThunkConfig>(
	'staking/redeemToken',
	async (token, { dispatch, extra: { sdk } }) => {
		const hash =
			token === 'vKwenta'
				? await sdk.kwentaToken.redeemVKwenta()
				: await sdk.kwentaToken.redeemVeKwenta()

		monitorTransaction({
			txHash: hash,
			chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
			onTxConfirmed: () => {
				dispatch(fetchStakeMigrateData())
			},
		})
	}
)

export const fetchEscrowData = createAsyncThunk<EscrowBalance, void, ThunkConfig>(
	'staking/fetchEscrowData',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_ESCROW_BALANCE

			const { escrowData, totalVestable } = await sdk.kwentaToken.getEscrowData()

			return {
				escrowData: escrowData.map((e) => ({
					...e,
					vestable: e.vestable.toString(),
					amount: e.amount.toString(),
					fee: e.fee.toString(),
				})),
				totalVestable: totalVestable.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchEscrowV2Data = createAsyncThunk<EscrowBalance, void, ThunkConfig>(
	'staking/fetchEscrowV2Data',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_ESCROW_BALANCE

			const { escrowData, totalVestable } = await sdk.kwentaToken.getEscrowV2Data()

			return {
				escrowData: escrowData.map((e) => ({
					...e,
					vestable: e.vestable.toString(),
					amount: e.amount.toString(),
					fee: e.fee.toString(),
				})),
				totalVestable: totalVestable.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchEstimatedRewards = createAsyncThunk<EstimatedRewards, void, ThunkConfig>(
	'staking/fetchEstimatedRewards',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_ESTIMATED_REWARDS

			const { estimatedKwentaRewards, estimatedArbRewards, estimatedOpRewards } =
				await sdk.kwentaToken.getEstimatedRewards()

			return {
				kwenta: estimatedKwentaRewards.toString(),
				arb: estimatedArbRewards.toString(),
				opReferral: estimatedOpRewards.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchFeesPaid = createAsyncThunk<FeesPaid, void, ThunkConfig>(
	'staking/fetchFeesPaid',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_FEES_PAID

			const epochPeriod = getEpochPeriod()
			const { epochStart, epochEnd } = getEpochDetails(epochPeriod)
			const network = selectSnxPerpsV2Network(getState())
			const futuresFeePaid = await sdk.kwentaToken.getFuturesFeeForAccount(
				wallet,
				epochStart,
				epochEnd,
				network
			)

			const epochPeriodOpReferral = getEpochPeriod(EPOCH_CONFIGS.opReferral)
			const { epochStart: opReferralEpochStart, epochEnd: opReferralEpochEnd } = getEpochDetails(
				epochPeriodOpReferral,
				EPOCH_CONFIGS.opReferral
			)
			const opReferralFeePaid =
				epochPeriodOpReferral < 0
					? ZERO_WEI
					: await sdk.kwentaToken.getFuturesFeeForAccount(
							wallet,
							opReferralEpochStart,
							opReferralEpochEnd,
							network
						)

			return {
				kwenta: futuresFeePaid.toString(),
				opReferral: opReferralFeePaid.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchReferralFeesPaid = createAsyncThunk<ReferralFeesPaid, void, ThunkConfig>(
	'staking/fetchReferralFeesPaid',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			if (!wallet) return ZERO_REFERRAL_FEES_PAID

			const epochPeriodOpReferral = getEpochPeriod(EPOCH_CONFIGS.opReferral)
			const { epochStart: opReferralEpochStart, epochEnd: opReferralEpochEnd } = getEpochDetails(
				epochPeriodOpReferral,
				EPOCH_CONFIGS.opReferral
			)

			const opReferralFeePaid =
				epochPeriodOpReferral < 0
					? ZERO_WEI
					: await sdk.referrals.getReferralFeesPaid(
							wallet,
							opReferralEpochStart,
							opReferralEpochEnd
						)

			return {
				opReferral: opReferralFeePaid.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchStakeMigrateData = createAsyncThunk<void, void, ThunkConfig>(
	'staking/fetchMigrateData',
	async (_, { dispatch }) => {
		dispatch(fetchPerpsAccounts({ providers: [PerpsProvider.SNX_V2_OP] }))
		dispatch(fetchStakingData())
		dispatch(fetchStakingV2Data())
		dispatch(fetchEscrowData())
		dispatch(fetchEscrowV2Data())
		dispatch(fetchEstimatedRewards())
		dispatch(fetchFeesPaid())
		dispatch(fetchReferralFeesPaid())
		dispatch(fetchClaimableRewards())
		dispatch(fetchMigrationDetails())
		dispatch(fetchApprovedOperators())
	}
)

export const fetchMigrationDetails = createAsyncThunk<void, void, ThunkConfig>(
	'staking/fetchMigrationDetails',
	async (_, { dispatch, getState }) => {
		const wallet = selectWallet(getState())
		const supportedNetwork = selectStakingSupportedNetwork(getState())
		if (!wallet || !supportedNetwork) return
		dispatch(fetchMigrationDeadline())
		dispatch(fetchTotalEscrowUnmigrated())
		dispatch(fetchRegisteredVestingEntryIDs())
		dispatch(fetchUnregisteredVestingEntryIDs())
		dispatch(fetchUnvestedRegisteredEntryIDs())
		dispatch(fetchToPay())
		dispatch(fetchEscrowMigratorAllowance())
		dispatch(fetchUnmigratedRegisteredEntryIDs())
	}
)

export const vestEscrowedRewards = createAsyncThunk<void, number[], ThunkConfig>(
	'staking/vestEscrowedRewards',
	async (ids, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'vest_escrowed_rewards',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.vestToken(ids)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)

			dispatch(fetchStakeMigrateData())
			dispatch(setOpenModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const vestEscrowedRewardsV2 = createAsyncThunk<void, number[], ThunkConfig>(
	'staking/vestEscrowedRewardsV2',
	async (ids, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'vest_escrowed_rewards_v2',
					hash: null,
				})
			)

			if (ids.length > 0) {
				const tx = await sdk.kwentaToken.vestTokenV2(ids)
				await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
				dispatch(fetchStakingV2Data())
				dispatch(fetchEscrowV2Data())
				dispatch(setOpenModal(null))
			}
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const claimStakingRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimStakingRewards',
	async (_, { dispatch, extra: { sdk } }) => {
		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'get_reward',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.claimStakingRewards()
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const claimStakingRewardsV2 = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimStakingRewardsV2',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_staking_rewards_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.claimStakingRewardsV2()
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const compoundRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/compoundRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'compound_rewards',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.compoundRewards()
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const bulkTransferEscrowEntries = createAsyncThunk<
	void,
	TransferEscrowEntriesInput,
	ThunkConfig
>(
	'staking/bulkTransferEscrowEntries',
	async ({ entries, recipient }, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'transfer_escrow_entries',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.bulkTransferFrom(wallet, recipient, entries)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakingV2Data())
			dispatch(fetchEscrowV2Data())
			dispatch(setOpenModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const transferEscrowEntry = createAsyncThunk<void, TransferEscrowEntryInput, ThunkConfig>(
	'staking/transferEscrowEntry',
	async ({ entry, recipient }, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'transfer_escrow_entry',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.transferFrom(wallet, recipient, entry)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakingV2Data())
			dispatch(fetchEscrowV2Data())
			dispatch(setOpenModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const fetchClaimableRewards = createAsyncThunk<ClaimableRewards, void, ThunkConfig>(
	'staking/fetchClaimableRewards',
	async (_, { getState, extra: { sdk } }) => {
		try {
			const wallet = selectWallet(getState())
			const epochPeriod = selectEpochPeriod(getState())

			if (!wallet) return ZERO_CLAIMABLE_REWARDS

			const { totalRewards: kwentaRewardsV2 } =
				await sdk.kwentaToken.getClaimableKwentaRewards(epochPeriod)

			const { totalRewards: arbRewards } = await sdk.kwentaToken.getClaimableArbRewards()
			const { totalRewards: opReferralRewards } =
				await sdk.kwentaToken.getClaimableOpReferralRewards()

			return {
				kwenta: kwentaRewardsV2.toString(),
				snx: '0',
				arb: arbRewards.toString(),
				opReferral: opReferralRewards.toString(),
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const fetchClaimableRewardsParams = createAsyncThunk<
	ClaimableRewardsParams,
	void,
	ThunkConfig
>('staking/fetchClaimableRewardsParams', async (_, { getState, extra: { sdk } }) => {
	try {
		const wallet = selectWallet(getState())
		const epochPeriod = selectEpochPeriod(getState())

		if (!wallet) return ZERO_CLAIMABLE_REWARDS_PARAMS

		const { claimableRewards: claimableKwentaRewardsV2 } =
			await sdk.kwentaToken.getClaimableKwentaRewards(epochPeriod)

		const { claimableRewards: claimableArbRewards } = await sdk.kwentaToken.getClaimableArbRewards()
		const { claimableRewards: claimableOpRewards } =
			await sdk.kwentaToken.getClaimableOpReferralRewards()

		return {
			kwentaV2: serializeClaimParams(claimableKwentaRewardsV2),
			arb: serializeClaimParams(claimableArbRewards),
			opReferral: serializeClaimParams(claimableOpRewards),
		}
	} catch (err) {
		logError(err)
		throw err
	}
})

export const claimMultipleKwentaRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleKwentaRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: { tradingRewards },
		} = getState()

		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_kwenta_rewards',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.claimMultipleAllRewards([
				unserializeClaimParams(tradingRewards.claimableRewardsParams.kwentaV2),
			])

			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const claimMultipleArbRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleArbRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: { tradingRewards },
		} = getState()

		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_arb_rewards',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.claimArbRewards(
				unserializeClaimParams(tradingRewards.claimableRewardsParams.arb)
			)

			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchEstimatedRewards())
			dispatch(fetchFeesPaid())
			dispatch(fetchReferralFeesPaid())
			dispatch(fetchClaimableRewards())
			dispatch(fetchClaimableRewardsParams())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const claimMultipleOpReferralRewards = createAsyncThunk<void, void, ThunkConfig>(
	'staking/claimMultipleOpReferralRewards',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			staking: { tradingRewards },
		} = getState()

		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_op_referral_rewards',
					hash: null,
				})
			)
			const tx = await sdk.kwentaToken.claimOpReferralRewards(
				unserializeClaimParams(tradingRewards.claimableRewardsParams.opReferral)
			)

			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchEstimatedRewards())
			dispatch(fetchFeesPaid())
			dispatch(fetchReferralFeesPaid())
			dispatch(fetchClaimableRewards())
			dispatch(fetchClaimableRewardsParams())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const stakeEscrow = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/stakeEscrow',
	async (amount, { dispatch, extra: { sdk } }) => {
		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'stake_escrowed',
					hash: null,
				})
			)
			const tx = await sdk.kwentaToken.stakeEscrowedKwenta(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const unstakeEscrow = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/unstakeEscrow',
	async (amount, { dispatch, extra: { sdk } }) => {
		try {
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'unstake_escrowed',
					hash: null,
				})
			)
			const tx = await sdk.kwentaToken.unstakeEscrowedKwenta(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

// TODO: Consider merging this with the (stake|unstake)Escrow actions.

export const stakeKwenta = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/stakeKwenta',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'stake',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.stakeKwenta(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const unstakeKwenta = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/unstakeKwenta',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		try {
			if (!wallet) throw new Error('Wallet not connected')
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'unstake',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.unstakeKwenta(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const stakeEscrowV2 = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/stakeEscrowV2',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'stake_escrow_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.stakeEscrowedKwentaV2(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const unstakeEscrowV2 = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/unstakeEscrowV2',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'unstake_escrow_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.unstakeEscrowedKwentaV2(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

// TODO: Consider merging this with the (stake|unstake)Escrow actions.

export const stakeKwentaV2 = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/stakeKwentaV2',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'stake_kwenta_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.stakeKwentaV2(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const unstakeKwentaV2 = createAsyncThunk<void, bigint, ThunkConfig>(
	'staking/unstakeKwentaV2',
	async (amount, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')

			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'unstake_kwenta_v2',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.unstakeKwentaV2(amount)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchStakeMigrateData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const approveOperator = createAsyncThunk<
	void,
	{ delegatedAddress: Address; isApproval: boolean },
	ThunkConfig
>(
	'staking/approveOperator',
	async ({ delegatedAddress, isApproval }, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())

		try {
			if (!wallet) throw new Error('Wallet not connected')
			dispatch(
				setTransaction({
					chainId: SnxV2NetworkIds.OPTIMISM_MAINNET,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_operator',
					hash: null,
				})
			)

			const tx = await sdk.kwentaToken.approveOperator(delegatedAddress, isApproval)
			await monitorAndAwaitTransaction(SnxV2NetworkIds.OPTIMISM_MAINNET, dispatch, tx)
			dispatch(fetchApprovedOperators())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const fetchApprovedOperators = createAsyncThunk<{ operators: string[] }, void, ThunkConfig>(
	'staking/fetchApprovedOperators',
	async (_, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		try {
			if (!wallet) return { operators: [] }

			const operatorsApprovalTxns = await sdk.kwentaToken.getApprovedOperators()
			const operatorStatus: { [key: string]: boolean } = {}
			for (const txn of operatorsApprovalTxns) {
				if (operatorStatus[txn.operator] === undefined) {
					operatorStatus[txn.operator] = txn.approved
				}
			}
			const operators = Object.keys(operatorStatus)
				.filter((operator) => operatorStatus[operator])
				.map((operator) => operator.toLowerCase())

			return {
				operators,
			}
		} catch (err) {
			logError(err)
			throw err
		}
	}
)
