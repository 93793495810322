import { wei } from '@kwenta/wei';
import throttle from 'lodash/throttle';
import { HermesClient } from '@pythnetwork/hermes-client';
import axios from 'axios';
import { formatEther, hexToString } from 'viem';
import { CG_BASE_API_URL, ETH_ADDRESS, ETH_COINGECKO_ADDRESS } from '../constants';
import * as sdkErrors from '../constants/errors';
import { DEFAULT_PRICE_SERVER_SOURCE, MARKETS, MARKETS_BY_PYTH_ID, PERPS_PYTH_IDS, SPOT_ASSETS_BY_PYTH_ID, } from '../constants/futures';
import { PERIOD_IN_SECONDS } from '../constants/period';
import { PRICE_UPDATE_THROTTLE, PRICE_UPDATE_TIMEOUT } from '../constants/prices';
import { SnxV2NetworkIds } from '../types/common';
import { MarketAssetByKey, MarketKeyByAsset, getDisplayAsset, getPythNetworkUrl, normalizePythId, } from '../utils/futures';
import { startInterval } from '../utils/interval';
import { scale } from '../utils/number';
export default class PricesService {
    constructor(sdk) {
        this.offChainPrices = {};
        this.onChainPrices = {};
        this.lastConnectionTime = Date.now();
        this.isConnected = false;
        this.initialConnectionEstablished = false;
        this.server = DEFAULT_PRICE_SERVER_SOURCE;
        this.CONNECTION_TIMEOUT = 15000; // 15 seconds
        this.MONITOR_INTERVAL = 1000; // 1 second
        this.throttleOffChainPricesUpdate = throttle((offChainPrices) => {
            this.sdk.context.events.emit('prices_updated', {
                prices: offChainPrices,
                type: 'off_chain',
                source: 'stream',
            });
        }, PRICE_UPDATE_THROTTLE);
        this.sdk = sdk;
        this.initializeConnection();
    }
    async initializeConnection() {
        await this.connectToPyth(this.server);
        this.initialConnectionEstablished = true;
        this.startMonitoring();
    }
    get currentPrices() {
        return {
            onChain: this.onChainPrices,
            offChain: this.offChainPrices,
        };
    }
    get pythIds() {
        return Object.values(PERPS_PYTH_IDS);
    }
    /**
     * @desc Get offchain price for a given market
     * @param marketKey - Futures market key
     * @returns Offchain price for specified market
     * @example
     * ```ts
     * const sdk = new KwentaSDK();
     * const price = sdk.prices.getOffchainPrice(FuturesMarketKey.sBTCPERP);
     * console.log(price);
     * ```
     */
    getOffchainPrice(marketAsset) {
        const price = this.offChainPrices[marketAsset];
        if (!price)
            throw new Error(`No price data for ${marketAsset}`);
        return price;
    }
    /**
     * @desc Start polling on-chain synth and market price updates
     * @param intervalTime - Polling interval in milliseconds
     * @example
     * ```ts
     * const sdk = new KwentaSDK();
     * await sdk.prices.startOnchainPriceUpdates(10000);
     * ```
     */
    async startOnchainPriceUpdates(intervalTime, chainId) {
        if (!this.ratesInterval) {
            this.ratesInterval = startInterval(async () => {
                try {
                    this.onChainPrices = await this.getOnChainPrices(chainId);
                    this.sdk.context.events.emit('prices_updated', {
                        prices: this.onChainPrices,
                        type: 'on_chain',
                    });
                }
                catch (err) {
                    this.sdk.context.logError(err);
                }
            }, intervalTime);
        }
    }
    async stopOnchainPriceUpdates() {
        if (this.ratesInterval) {
            clearInterval(this.ratesInterval);
            this.ratesInterval = undefined;
        }
    }
    onPricesUpdated(listener) {
        return this.sdk.context.events.on('prices_updated', listener);
    }
    removePricesListener(listener) {
        return this.sdk.context.events.off('prices_updated', listener);
    }
    removePricesListeners() {
        this.sdk.context.events.removeAllListeners('prices_updated');
    }
    onPricesConnectionUpdated(listener) {
        return this.sdk.context.events.on('prices_connection_update', listener);
    }
    removeConnectionListeners() {
        this.sdk.context.events.removeAllListeners('prices_connection_update');
    }
    async getOnChainPrices(chainId = SnxV2NetworkIds.OPTIMISM_MAINNET) {
        const { PerpsV2MarketData } = this.sdk.context.contractConfigs[chainId]?.snxV2 ?? {};
        const { SynthUtil } = this.sdk.context.contractConfigs[chainId]?.common ?? {};
        const client = this.sdk.context.clients[chainId];
        if (!SynthUtil || !client) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const synthRequest = {
            ...SynthUtil,
            functionName: 'synthsRates',
        };
        // SynthUtil is used on both L1 and L2 where as Perps is only L2
        const calls = PerpsV2MarketData
            ? [synthRequest, { ...PerpsV2MarketData, functionName: 'allProxiedMarketSummaries' }]
            : [synthRequest];
        const synthPrices = {};
        const [synthsRates, perpsMarkets] = (await client.multicall({
            allowFailure: false,
            contracts: calls,
        }));
        const synths = synthsRates[0];
        const synthRates = synthsRates[1];
        synths.forEach((currencyKeyBytes32, i) => {
            const currencyKey = hexToString(currencyKeyBytes32, { size: 32 });
            const marketAsset = MarketAssetByKey[currencyKey];
            const rate = Number(formatEther(BigInt(synthRates[i])));
            const price = wei(rate);
            synthPrices[currencyKey] = price;
            if (marketAsset)
                synthPrices[marketAsset] = price;
        });
        perpsMarkets?.forEach((market) => {
            const marketAsset = hexToString(market.asset, { size: 32 });
            const price = wei(market.price);
            if (marketAsset)
                synthPrices[marketAsset] = price;
        });
        return synthPrices;
    }
    async getOffChainPrices(pythIds = this.pythIds) {
        const pythPrices = await this.pyth.getLatestPriceUpdates(pythIds);
        if (!pythPrices || !pythPrices.parsed) {
            throw new Error('Failed to get off chain prices');
        }
        return this.formatOffChainPrices(pythPrices.parsed);
    }
    async getPreviousDayPrices(isYesterdayWorkingTime = false) {
        const minTimestamp = Math.floor((Date.now() - PERIOD_IN_SECONDS.ONE_DAY * 1000) / 1000);
        const filteredPythIds = Object.values(MARKETS)
            .filter((m) => !!m.isClosable)
            .map((m) => m.pythId);
        const pythIds = this.pythIds.filter((id) => !filteredPythIds.includes(id));
        const mainPrices = await this.getPricesForTimestamp(minTimestamp, pythIds);
        let filterPrices = [];
        try {
            if (isYesterdayWorkingTime) {
                filterPrices = await this.getPricesForTimestamp(minTimestamp, filteredPythIds);
            }
        }
        catch (err) {
            this.sdk.context.logError(err);
        }
        return [...mainPrices, ...filterPrices];
    }
    async getPricesForTimestamp(timestamp, pythIds) {
        const data = await this.pyth.getPriceUpdatesAtTimestamp(timestamp, pythIds);
        if (!data || !data.parsed) {
            throw new Error('Failed to get off chain prices');
        }
        const formattedPrices = this.formatOffChainPrices(data.parsed);
        return Object.entries(formattedPrices)
            .map(([key, value]) => ({
            synth: getDisplayAsset(key),
            rate: value.toString(),
        }))
            .filter((el) => el.synth !== null);
    }
    /**
     * @desc Get pyth price update data for a given market
     * @param marketAsset Futures market asset
     * @returns Pyth price update data
     */
    async getPythPriceUpdateData(marketAsset) {
        const pythId = MARKETS[MarketKeyByAsset[marketAsset]]?.pythId;
        if (!pythId)
            throw new Error(sdkErrors.NO_PYTH_ID);
        const updateData = await this.pyth.getLatestPriceUpdates([pythId]);
        if (!updateData || !updateData.binary) {
            throw new Error('Failed to get price update data');
        }
        return updateData.binary.data;
    }
    async batchGetCoingeckoPrices(tokenAddresses, platform = 'optimistic-ethereum', include24hrChange = false) {
        const response = await axios.get(`${CG_BASE_API_URL}/simple/token_price/${platform}?contract_addresses=${tokenAddresses
            .join(',')
            .replace(ETH_ADDRESS, ETH_COINGECKO_ADDRESS)}&vs_currencies=usd${include24hrChange ? '&include_24hr_change=true' : ''}`);
        return response.data;
    }
    formatOffChainPrices(pythPrices) {
        return pythPrices.reduce((acc, p) => {
            let price = this.formatPythPrice(p.price);
            // Have to handle inconsistent id formatting between ws and http
            const id = normalizePythId(p.id);
            const markets = MARKETS_BY_PYTH_ID[id];
            if (markets) {
                markets?.forEach((market) => {
                    if (market.transform) {
                        price = market.transform(price);
                    }
                    acc[market.asset] = price;
                });
            }
            else {
                // Handle non perp market pyth ids
                const token = SPOT_ASSETS_BY_PYTH_ID[id];
                if (token) {
                    acc[token] = price;
                }
            }
            return acc;
        }, {});
    }
    async connectToPyth(server) {
        this.closeConnection();
        this.pyth = new HermesClient(getPythNetworkUrl(server), {
            timeout: PRICE_UPDATE_TIMEOUT,
        });
        await this.subscribeToPythPriceUpdates();
        this.lastConnectionTime = Date.now();
    }
    startMonitoring() {
        if (this.connectionMonitorId) {
            clearInterval(this.connectionMonitorId);
        }
        this.connectionMonitorId = setInterval(() => {
            if (this.initialConnectionEstablished &&
                Date.now() - this.lastConnectionTime > this.CONNECTION_TIMEOUT) {
                this.switchConnection();
            }
        }, this.MONITOR_INTERVAL);
    }
    setConnected(connected) {
        if (connected !== this.isConnected) {
            this.isConnected = connected;
            this.sdk.context.events.emit('prices_connection_update', {
                connected: connected,
            });
        }
    }
    async switchConnection() {
        this.server = this.server === 'PRIMARY' ? 'BACKUP' : 'PRIMARY';
        await this.connectToPyth(this.server);
    }
    formatPythPrice(price) {
        return scale(wei(price.price), price.expo);
    }
    async subscribeToPythPriceUpdates() {
        try {
            this.offChainPrices = await this.getOffChainPrices();
            this.sdk.context.events.emit('prices_updated', {
                prices: this.offChainPrices,
                type: 'off_chain',
                source: 'fetch',
            });
        }
        catch (err) {
            this.sdk.context.logError(err);
        }
        const stream = await this.pyth.getPriceUpdatesStream(this.pythIds, {
            allowUnordered: false,
            benchmarksOnly: true,
            encoding: 'hex',
            parsed: true,
        });
        this.eventSource = stream;
        this.eventSource.onmessage = this.handleEvent.bind(this);
        this.eventSource.onerror = this.handleConnectionError.bind(this);
    }
    handleConnectionError(error) {
        this.sdk.context.logError(error);
        this.setConnected(false);
        this.sdk.context.events.emit('prices_connection_update', {
            connected: false,
            error: new Error('Pyth prices connection failed'),
        });
    }
    handleEvent(event) {
        const parsedEvent = JSON.parse(event.data);
        if (!parsedEvent || !parsedEvent.parsed)
            return;
        this.offChainPrices = {
            ...this.offChainPrices,
            ...this.formatOffChainPrices(parsedEvent.parsed),
        };
        this.setConnected(true);
        this.lastConnectionTime = Date.now();
        this.throttleOffChainPricesUpdate(this.offChainPrices);
    }
    closeConnection() {
        if (this.eventSource) {
            this.eventSource.removeEventListener('message', this.handleEvent.bind(this));
            this.eventSource.close();
            this.eventSource = undefined;
        }
    }
}
